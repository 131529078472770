import { userActionTypes } from "../actiontypes/users";

export function pageSize(state = 20, action) {

    if (typeof state === 'function') {
        state = state() || {};
    }

    switch (action.type) {
        case userActionTypes.VACANCY_PAGE_SIZE:
           return action.size
        default:
            return state;
    }
}
