import React from 'react';
import Layout from '../../components/Layout';
import Activate from '../../components/Account/Activate';

const ActivateRoot = props => {
  return (
    <Layout 
      parent="Vacatures"
      title="Profiel activeren">
        <Activate />
    </Layout>
  )
}

export default ActivateRoot;