import React from 'react';
import Layout from '../../components/Layout';
import RemoveAccount from '../../components/Account/Remove';

const RemoveRoot = props => {
  return (
    <Layout 
      parent="Vacatures"
      subParent="Mijn profiel"
      title="Profiel verwijderen">
        <RemoveAccount />
    </Layout>
  )
}

export default RemoveRoot;
