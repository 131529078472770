import React, {Fragment} from 'react';
import Header from '../Header';
import Footer from '../Footer';

const Layout = (props) => (
  <Fragment>
    <Header
      parent={props.parent || ''}
      subParent={props.subParent || ''}
      title={props.title || ''} />
    {props.children}
    <Footer />
  </Fragment>
)

export default Layout
