import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import "core-js";
import "regenerator-runtime/runtime";

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import {Provider} from 'react-redux';
import { store } from './shared/store';


document.addEventListener("touchstart", function() {},false);
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>
, document.getElementById('app'));


(() => {
    function slideinfrombottom(element, duration=1000) {
        if (element?.dataset['stickyposition'] !== 'bottom')
            return
        const rect = element.getBoundingClientRect()
        element.style.bottom = "-" + rect.height + "px"
        setTimeout(() => {
            element.style.transitionDuration = duration + "ms";
            element.style.transitionProperty = "bottom";
            element.style.bottom = "0"
            setTimeout(() => {
                element.style.removeProperty('transition-duration')
                element.style.removeProperty('transition-property')
                element.style.removeProperty('bottom')
            }, duration)
        }, 0)
    }

    function keepStickiesVisible() {
        const stickies = [
            ...document.querySelectorAll('[data-stickyrole="sticky"]')
        ]
        stickies.forEach(sticky => {
            var copy = sticky.nextSibling
            if (copy?.dataset['stickyrole'] !== 'copy') {
                copy = sticky.cloneNode(true)
                copy.dataset['stickyrole'] = 'copy'
                sticky.after(copy)
            }
            const initial = (copy.dataset['stickyposition'] === undefined)
            const sticky_rect = sticky.getBoundingClientRect()
            if (sticky_rect.top < 0) {
                copy.dataset['stickyposition'] = 'top'
            } else if (sticky_rect.bottom > window.innerHeight) {
                copy.dataset['stickyposition'] = 'bottom'
            } else {
                copy.dataset['stickyposition'] = 'hide'
            }
            copy.style.left = sticky_rect.left + "px"
            copy.style.width = sticky_rect.width + "px"

            var background = sticky.previousSibling
            if (background?.dataset['stickyrole'] === 'background') {
                background.style.paddingLeft = sticky_rect.right + 10 + "px"
                background.style.height = "auto"
                background.dataset['stickyposition'] = copy.dataset['stickyposition']
                if (background.dataset['stickyposition'] !== 'hide') {
                    const background_rect = background.getBoundingClientRect()
                    if (background_rect.height > sticky_rect.height) {
                        // text too large or screen to small
                        background.dataset['stickyposition'] = 'hide'
                    } else {
                        background.style.height = sticky_rect.height + "px"
                    }
                }
            }
            if (initial) {
                slideinfrombottom(copy)
                slideinfrombottom(background)
            }
        })
    };
    window.onscroll = keepStickiesVisible
    window.onresize = keepStickiesVisible
    setTimeout(keepStickiesVisible, 2000)
})();
