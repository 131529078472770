export const savedVacanciesActionTypes = {
  GET_SAVED_VACANCIES_REQUEST: 'GET_SAVED_VACANCIES_REQUEST',
  GET_SAVED_VACANCIES_SUCCESS: 'GET_SAVED_VACANCIES_SUCCESS',
  GET_SAVED_VACANCIES_FAILURE: 'GET_SAVED_VACANCIES_FAILURE',

  ADD_SAVED_VACANCY_REQUEST: 'ADD_SAVED_VACANCY_REQUEST',
  ADD_SAVED_VACANCY_SUCCESS: 'ADD_SAVED_VACANCY_SUCCESS',
  ADD_SAVED_VACANCY_FAILURE: 'ADD_SAVED_VACANCY_FAILURE',

  REMOVE_SAVED_VACANCY_REQUEST: 'REMOVE_SAVED_VACANCY_REQUEST',
  REMOVE_SAVED_VACANCY_SUCCESS: 'REMOVE_SAVED_VACANCY_SUCCESS',
  REMOVE_SAVED_VACANCY_FAILURE: 'REMOVE_SAVED_VACANCY_FAILURE'
}
