import React, {useEffect} from 'react';

import {withRouter} from 'react-router-dom';

import * as F from '../../assets/styles/shared/forms.module.scss';

import AccountWrapper from './AccountWrapper';

import {userActions} from '../../actions';

import {connect} from 'react-redux';

const Activate = (props) => {

  const handleError = (errors) => {
    return (
      <div
        className={F.formError}
        key={errors}>
        {errors.detail}
      </div>
    )
  }

  useEffect(() => {
    return () => {
      if(props.match.params) {
        if(!props.error) {
          let params = props.match.params;
          props.dispatch(userActions.activate(params));
        }
      }
    };
  }, [props])

  return(
    <AccountWrapper title="">
      {props.error &&  handleError(props.error.body)}
      <p>Uw account wordt geactiveerd.</p>
    </AccountWrapper>
  )
}



const mapStateToProps = (state, ownProps) => {
  return {
    error: state.authentication.error,
    isRegistered: state.authentication.isRegistered
  }
}

export default connect(mapStateToProps)(withRouter(Activate));
