import React, {Fragment, useState, useEffect} from 'react';
import {connect} from 'react-redux';

import {withRouter} from 'react-router-dom';
import MediaQuery from 'react-responsive';

import SiteSettings from '../../SiteSettings';
import {isEmptyObject} from '../../helpers.js';

import Loader from '../Vacancies/Loader';

import classNames from 'classnames';

import ReactiveBase from '@appbaseio/reactivesearch/lib/components/basic/ReactiveBase';
import SelectedFilters from '@appbaseio/reactivesearch/lib/components/basic/SelectedFilters';
import ReactiveList from '@appbaseio/reactivesearch/lib/components/result/ReactiveList';

import * as S from './Vacancies.module.scss';

import Vacancy from './Vacancy';

import { NavLink } from 'react-router-dom';

import SavedSearches from '../SavedSearches';
import ShareSearch from './ShareSearch';
import BaseFilter from '../Filter/BaseFilter.js';
import Pagination from './Pagination';

import {useScroll} from '../../context/scroll.context';

import {userActions} from '../../actions/auth.actions';

import LoginInfo from './LoginInfo.js';

const Vacancies = props => {

  const [isMobileFilterOpen, setisMobileFilterOpen] = useState(false);
  const [hasUser, sethasUser] = useState(false);
  const [searchQuery, setsearchQuery] = useState();
  const [scrollPos, setscrollPos] = useScroll();
  const [loaded, setLoaded] = useState(false);
  const [refreshPage, setRefreshpage] = useState(false);

  const handleScrollPos = () => {
    const el =  document.scrollingElement || document.documentElement;
    setscrollPos(el.scrollTop);
  };

  const defaultQuery = () => {
    const WBdEUObj = {
      "query": {
        "bool": {
          "must": [
            {
              "term": {
                "type.raw": SiteSettings.REACT_APP_SEARCH_PARAMS
              }
            }
          ],
          "must_not": [
            {"term": {"country.raw": "Onbekend"}},
            {"term": {"city.raw": "Onbekend"}},
            {"term": {"discipline.raw": "Onbekend"}},
            {"term": {"organisation_name.raw": "Onbekend"}},
            {"term": {"contract_type.raw": "Onbekend"}}
          ]
        }
      }
    };
    const NCIFObj = {
      "query": {
        "bool": {
          "must": [
            {
              "term": {
                "type.raw": SiteSettings.REACT_APP_SEARCH_PARAMS
              }
            }
          ],
          "must_not": [
            {"term": {"country.raw": "Onbekend"}},
            {"term": {"discipline.raw": "Onbekend"}},
            {"term": {"organisation_name.raw": "Onbekend"}},
            {"term": {"work_think_level.raw": "Professional"}}
          ]
        }
      }
    };
    return (SiteSettings.REACT_APP_SEARCH_PARAMS === "NCIF" ? NCIFObj : WBdEUObj)
  };

  const handleDisplayCount = (e) => {
    let count = parseInt(e.target.value);
    props.dispatch(userActions.pageSize(count))
  }

  const renderCount = (label, count, isSelected) => {
    return(
      <div className={classNames(S.checkboxItem, {[S.checkboxItemSelected]: isSelected})}>
        {label}
        <span className={classNames(S.count, {[S.countSelected]: isSelected})}>
          ({count})
        </span>
      </div>
    )
  }

  const handleSavedVacancies = () => {
    let vacancies;
    if(hasUser) {
      let userFavs = props.authentication.user.favourites;
      vacancies = [...userFavs.map(item => item.id)];
    } else {
      vacancies = JSON.parse(localStorage.getItem('savedVacancies'));
    }
    return vacancies;
  }

  const refreshRefClick = () => {
    if(refreshPage) {
      let link = document.getElementById('SavedVacanciesFilter-Toon alleen opgeslagen vacatures');
      link.click();
      setTimeout(() => {
        link.click();
      }, 1000);
    }
  }

  const handleMobileFilter = (bool) => {
    if(bool) {
      document.getElementsByTagName("body")[0].style.overflow = 'hidden';
    } else {
      document.getElementsByTagName("body")[0].style.overflow = '';
    }
    setisMobileFilterOpen(bool);
  }

  useEffect(() => {
    if(isEmptyObject(props.user)) {
      sethasUser(false);
    } else {
      if(props.user.user) {
        sethasUser(true);
      } else {
        sethasUser(false);
      }
    }
  }, [props.user]);

  useEffect(() => {
    const el =  document.scrollingElement || document.documentElement;
    if(loaded) {
      el.scrollTop = scrollPos;
    }
  }, [loaded, scrollPos]);

  useEffect(() => {
    var url = new URL(window.location);
    props.history.push({
      pathname: '/',
      search: url.search
    });
  }, [
    props.pageSize,
    props.history
  ])

  const handleRouteChange = (url) => {
    props.history.push({
      pathname: '/',
      search: url
    });
  }

  const renderResultStats = stats => {
    if(stats) setLoaded(true);
    return (
      <Fragment>
      <div className={S.stats}>
        {`${stats.numberOfResults} Vacatures`}
      </div>
      <div className={S.changePageSize}>
      <span>Vacatures per pagina: </span>
        <select
        name="changePageSize"
          id="changePageSize"
          onChange={handleDisplayCount}
          defaultValue={props.pageSize}>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>
      </Fragment>
    )
  }

  const renderAllData = data => {
    return (
       <Vacancy
            key={data.id}
            id={data.id}
            title={data.function_title}
            organisation={data.organisation_name}
            location={`${data.country}, ${data.city}`}
            endDate={data.closing_date}
            grade={data.grade}
            discipline={data.discipline}
            contract_type={data.contract_type}
            work_think_level={data.work_think_level}
            user={props.user}
            handleScrollPos={handleScrollPos}
            refreshRefClick={refreshRefClick}
          />
    )
  }

  const renderNoResult = () => {
    return (
      <div className={S.noResults}>
        <h3>Geen vacatures gevonden.</h3>
        <br />
        <p>
        Pas uw trefwoord of filters aan.
        </p>
      </div>
    )
  }

  const handleSelectedFilters = (filters) => {
    if(filters.hasOwnProperty('SavedVacanciesFilter')) {
      if(filters.SavedVacanciesFilter.value.length > 0) {
        setRefreshpage(true);
      } else {
        setRefreshpage(false);
      }
    }
  }

  const renderPagination = data => {
    return(
      <Pagination {...data} />
    )
  }

  return(
      <ReactiveBase
        app="vacancy"
        url={SiteSettings.REACT_APP_ES_URL}
      >
        <div className={S.inner}>
          <div className={classNames(S.wrapper, {[S.open]: isMobileFilterOpen} )}>
            <aside className={classNames(S.sidebar, [S[SiteSettings.REACT_APP_THEME]], {[S.open]: isMobileFilterOpen})}>
              <MediaQuery maxWidth={700}>
                <div
                  onClick={() => handleMobileFilter(false)}
                  className={classNames(S.filterToggle, S.inFilter)}>
                  Sluit filters
                </div>
              </MediaQuery>
              <div className={S.filterWrapper}>
                <MediaQuery minWidth={701}>
                  {
                    hasUser ? (
                      <Fragment>
                        <NavLink className={S.link} to='/account'>Mijn profiel</NavLink>
                        <NavLink className={S.link} to='/logout'>Uitloggen</NavLink>
                      </Fragment>
                    ): (
                      <NavLink className={S.loginLink} to='/login'>
                        Inloggen <LoginInfo
                          text={`Door in te loggen kan je zoekopdrachten personaliseren en bewaren
                            en jezelf abonneren op updates van jouw zoekopdrachten.
                            Zo blijf je altijd op de hoogte van nieuwe, relevante vacatures.
                            Je kunt je profiel op elk moment verwijderen.`
                          }
                        />
                      </NavLink>
                    )
                  }
                </MediaQuery>

                <SavedSearches
                  isLoggedIn={hasUser}
                  handleRouteCHange={handleRouteChange}
                  rawQuery={searchQuery && searchQuery}/>
                <ShareSearch />

                <BaseFilter
                  handleSavedVacancies={handleSavedVacancies}
                  defaultQuery={defaultQuery}
                  renderCount={renderCount}
                />

              </div>
            </aside>
            <section className={S.list}>
              <MediaQuery maxWidth={700}>
                <div className={S.userActions}>
                  <div
                    onClick={() => handleMobileFilter(true)}
                    className={S.filterToggle}>
                    Open filters
                  </div>
                  {
                    props.user.loggedIn ? (
                      <NavLink to='/account'>Mijn profiel</NavLink>
                    ): (
                      <NavLink to='/login'>Login</NavLink>
                    )
                  }
                </div>
              </MediaQuery>

              <SelectedFilters
                className={S.selectedFilters}
                onChange={handleSelectedFilters}
                showClearAll={true}
                clearAllLabel="x Alle filters"
                innerClass={{
                  button: classNames(S.button, [S[SiteSettings.REACT_APP_THEME]])
                }}
              />
              <ReactiveList
                componentId="SearchResult"
                scrollOnChange={false}
                stream={false}
                dataField="publication_date"
                sortBy="desc"
                size={props.pageSize}
                renderItem={renderAllData}
                renderResultStats={renderResultStats}
                pagination
                loader={<Loader />}
                paginationAt="both"
                renderNoResults={renderNoResult}
                renderPagination={renderPagination}
                URLParams
                innerClass={{
                  pagination: S.pagination
                }}
                defaultQuery={defaultQuery}
                react={{
                  and: BaseFilter.filtersNames
                }}
                onQueryChange={
                  function(prevQuery, nextQuery) {
                    setsearchQuery(nextQuery)
                  }
                }
              />
            </section>
        </div>
        </div>
      </ReactiveBase>
  )
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    pageSize: state.pageSize
  }
}

export default withRouter(connect(mapStateToProps)(Vacancies));
