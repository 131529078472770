import React, {useState} from 'react';
import {withRouter} from 'react-router-dom';
import classNames from 'classnames';
import * as F from '../../assets/styles/shared/forms.module.scss';
import { connect } from 'react-redux';
import {userActions} from "../../actions";
import * as S from './Account.module.scss';

import AccountWrapper from './AccountWrapper';

import {NavLink} from 'react-router-dom';

const Login = (props) => {

  const [form, setValues] = useState({
    username: '',
    password: ''
  });

  const updateField = e => {
    setValues({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let next = "/";
    if(document.location.search.includes('next=')){
      next = "/?" + document.location.search.substr(6);
    }
    if(form.username && form.password) {
      props.dispatch(userActions.login(form.username, form.password, next))
    }
  };

  const handleError = (errors) => {
    if(errors.detail){
        return (<div className={F.formError} key={errors.detail}>{errors.detail}</div>);
    }
    return (
      Object.keys(errors)
        .map(er=> {
          return errors[er].map(status => {
            return (
              <div className={F.formError} key={status}>
              {status}
            </div>
            )
          })
        })
    )
  }

  return (
    <AccountWrapper title="Inloggen">
    <p className={S.loginInfo}>
    Door in te loggen kan je zoekopdrachten personaliseren en bewaren
    en jezelf abonneren op updates van jouw zoekopdrachten.
    Zo blijf je altijd op de hoogte van nieuwe, relevante vacatures.
    Je kunt je profiel op elk moment verwijderen.
    </p>
     {props.error &&  handleError(props.error.body)}
      <form onSubmit={handleSubmit}>
        <div className={F.formGroup}>
            <label htmlFor="username">E-mailadres</label>
            <input
              className={F.formControl}
              id="username"
              type="text"
              value={form.username}
              name="username"
              onChange={updateField} />
        </div>
        <div className={classNames(F.formGroup, F.lastItem)}>
          <label htmlFor="password">Wachtwoord</label>
          <input
            className={F.formControl}
            value={form.password}
            name="password"
            type="password"
            onChange={updateField}
            id="password"
          />
          <NavLink className={S.link}  to="/forgot">Wachtwoord vergeten?</NavLink>
        </div>
        <input
          className={F.submit}
          disabled={!form.password || !form.username}
          type="submit"
          value="Inloggen"/>
          <div>
            Heeft u geen profiel? <NavLink to="/create">Dan kunt u hier een profiel maken</NavLink>
          </div>
      </form>
    </AccountWrapper>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    error: state.authentication.error
  }
}
export default withRouter(connect(mapStateToProps)(Login));
