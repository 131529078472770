import React, {useState, Fragment} from 'react';

import {NavLink} from 'react-router-dom';

import * as S from './Account.module.scss';
import * as F from '../../assets/styles/shared/forms.module.scss';
import classNames from 'classnames';

import AccountWrapper from './AccountWrapper';

import {userActions} from '../../actions';
import { userActionTypes } from '../../actiontypes/users';

import {connect} from 'react-redux';

const Create = (props) => {
  const [p1class, setP1class] = React.useState("")
  const [p2class, setP2class] = React.useState("")

  const [error, seterror] = useState()

  const [form, setValues] = useState({
    username: '',
    password: '',
    password_again: ''
  });

  const updateField = e => {
    setValues({
      ...form,
      [e.target.name]: e.target.value
    });
    if (e.target.name === 'password') {
      checkPassword(e.target.value)
    } else if (e.target.name === 'password_again') {
      checkPasswordAgain(e.target.value)
    }
  };

  const password_validate = p  => {
    let re = {
      'capital' : /[A-Z]/,
      'digit'   : /\d/,
      'full'    : /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])([^\s]){8,16}$/
    };
    return re.full.test(p);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    seterror('')
    props.dispatch({type: userActionTypes.REGISTER_REQUEST})
    if(form.username && form.password) {
      if(password_validate(form.password)) {
        if(form.password === form.password_again) {
          props.dispatch(userActions.register(form))
        } else {
          seterror('Wachtwoorden zijn niet gelijk');
        }
      } else {
        seterror('Wachtwoord voldoet niet aan de eisen');
      }
    }
  };

  const handlePasswordBlur = (e) => {
    e.preventDefault();
    seterror('')
    if (!form.password) {
      setP1class("")
    }
    if (!form.password_again) {
      setP2class("")
    }
    if(form.password && form.password_again) {
      if(password_validate(form.password)) {
        if(form.password === form.password_again) {
          setP1class(F.passwordValid)
          setP2class(F.passwordValid)
        } else {
          seterror('Wachtwoorden zijn niet gelijk');
        }
      } else {
        seterror('Wachtwoord voldoet niet aan de eisen');
      }
    }
  }

  const handleError = (errors) => {
    return (
      Object.keys(errors)
        .map(er=> {
          return errors[er].map(status => {
            return (
              <div 
              className={F.formError}
              key={status}>
              {status}
            </div>
            )
          })
        })
    )
  }

  const checkPassword = (value) => {
    if (!value) return null
    const isValid = (
      value &&
      password_validate(value)
    )
    setPasswordClass(value, isValid, true)
  }

  const checkPasswordAgain = (value) => {
    if (!(value)) return null
    const isValid = (
      form.password === value &&
      password_validate(value)
    )
    setPasswordClass(value, isValid, false)
  }

  const setPasswordClass = (value, isValid, primary=true) => {
    const setClass = primary ? setP1class : setP2class
    if (!value) {
      setClass("")
    } else if (value.length < 8 && (primary || form.password !== form.password_again)) {
      setClass(F.passwordTooShort)
    } else if (isValid) {
      setClass(F.passwordValid)
    } else {
      setClass(F.passwordInvalid)
    }
  }

  return(
    <AccountWrapper title="Nieuw profiel">
      <p>Uw gegevens worden alleen gebruikt zodat u zoekopdrachten en vacatures op kunt slaan en we updates over nieuwe vacatures kunnen sturen. U kunt uw profiel op elk moment verwijderen.</p>
      <form onSubmit={handleSubmit}>
      {error && <div className={F.formError}>{error}</div>}
      {props.error &&  handleError(props.error.body)}
      {props.isRegistered ? (
        <div>U bent aangemeld, bekijk uw mail en activeer uw account</div>
      ) : (
        <Fragment>
          <div className={F.formGroup}>
              <label htmlFor="username">E-mailadres</label>
              <input
                className={F.formControl}
                id="username"
                type="email"
                value={form.username}
                name="username"
                onChange={updateField} />
          </div>
          <div className={F.formGroup}>
            <label htmlFor="password">Wachtwoord</label>
            <input
              className={classNames(F.formControl, p1class)}
              value={form.password}
              name="password"
              type="password"
              onChange={updateField}
              id="password"
              onBlur={handlePasswordBlur}
            />
            <small className={S.infoText}>Minimaal 8 karakters, minimaal 1 letter, minimaal 1 speciaal karakter</small>
          </div>
          <div className={classNames(F.formGroup, F.lastItem)}>
            <label htmlFor="password_again">Bevestig wachtwoord</label>
            <input
              className={classNames(F.formControl, p2class)}
              value={form.password_again}
              name="password_again"
              type="password"
              onChange={updateField}
              id="password_again"
              onBlur={handlePasswordBlur}
            />
            
          </div>
          <input
            className={F.submit}
            disabled={!form.password || !form.username}
            type="submit"
            value="Registreren"/>
            <div>
              Heeft u al een profiel? <NavLink to="/login">Dan kunt u hier inloggen.</NavLink>
            </div>
            </Fragment>
      )}
      </form>

    </AccountWrapper>
  )
}



const mapStateToProps = (state, ownProps) => {
  return {
    error: state.authentication.error,
    isRegistered: state.authentication.isRegistered
  }
}

export default connect(mapStateToProps)(Create);
