import React from 'react';
import Layout from '../../components/Layout';
import Forgot from '../../components/Account/Forgot';

const ForgotRoot = props => {
  return (
    <Layout
      parent="Vacatures"
      title="Wachtwoord vergeten">
        <Forgot />
    </Layout>
  )
}

export default ForgotRoot;