import React, {useState, Fragment} from 'react';
import classNames from 'classnames';

import AccountWrapper from '../Account/AccountWrapper';
import * as F from '../../assets/styles/shared/forms.module.scss';

import {userActions} from '../../actions/auth.actions';

import {connect} from 'react-redux';

const PasswordReset = props => {

  const [form, setValues] = useState({
    new_password: '',
    re_new_password: '',
    current_password: ''
  });

  const [error, setError] = useState('');

  const updateField = e => {
    setValues({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError('')
    if(form.new_password && form.re_new_password && form.current_password) {
      if(form.new_password === form.re_new_password) {
        props.dispatch(userActions.setPassword(form))
      } else {
        setError('Wachtwoord zijn niet gelijk aan elkaar')
      }
    }
  };
  
  const handleError = (errors) => {
    return (
      Object.keys(errors)
        .map(er=> {
          return errors[er].map(status => {
            return (
              <div className={F.formError} key={status}>
              {status}
            </div>
            )
          })
        })
    )
  }
  return(
    <AccountWrapper 
      title="Wachtwoord wijzigen"
      goBackTitle="Terug naar mijn profiel"
      goBackLink={"/account"}>
      <form onSubmit={handleSubmit}>
      {props.error &&  handleError(props.error.body)}
      {error && <div className={F.formError}>{error}</div>}
      {props.isRegistered ? (
        <div>U bent aangemeld, bekijk uw mail en activeer uw account</div>
      ) : (
        <Fragment>
          <div className={F.formGroup}>
              <label htmlFor="current_password">Huidige wachtwoord</label>
              <input
                className={F.formControl}
                id="current_password"
                type="password"
                value={form.current_password}
                name="current_password"
                onChange={updateField}
                placeholder="Huidige wachtwoord" />
          </div>
          <div className={F.formGroup}>
              <label htmlFor="new_password">Wachtwoord</label>
              <input
                className={F.formControl}
                id="new_password"
                type="password"
                value={form.new_password}
                name="new_password"
                onChange={updateField}
                placeholder="Wachtwoord" />
          </div>
          <div className={classNames(F.formGroup, F.lastItem)}>
            <label htmlFor="re_new_password">Wachtwoord nog een keer</label>
            <input
              className={F.formControl}
              value={form.re_new_password}
              name="re_new_password"
              type="password"
              onChange={updateField}
              id="re_new_password"
              placeholder="Wachtwoord nog een keer"
            />
          </div>
          <input
            className={F.submit}
            type="submit"
            disabled={form.new_password === '' && form.re_new_password === '' && form.current_password === ''}
            value="Wijzigen"/>
            </Fragment>
      )}
      </form>
    </AccountWrapper>
  )
}


const mapStateToProps = (state, ownProps) => {
  return {
    error: state.authentication.error,
    isRegistered: state.authentication.isRegistered
  }
}

export default connect(mapStateToProps)(PasswordReset);