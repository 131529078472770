import React, {useState} from 'react';
import * as S from './Header.module.scss';
import classNames from 'classnames';

import {NavLink} from 'react-router-dom';

import SiteSettings from '../../SiteSettings';
import logo from '../../assets/images/logo.svg';
import Solong from './solong';


const Header = (props) => (
  <header className={S.root}>
    <div className={S.logo}>
    <img alt={SiteSettings.REACT_APP_TITLE} src={logo} />
    </div>
    <Solong />
    <div className={classNames(S.navbar, [S[SiteSettings.REACT_APP_THEME]])}>
      <div className={S.inner}>
        <div className={S.nav}>
          <a
            className={classNames({[S.hasTitle]: props.title})}
            href={SiteSettings.REACT_APP_MAIN_LINK}>
            {SiteSettings.REACT_APP_TITLE}
          </a>
          {SiteSettings.REACT_APP_THEME === 'ncif' ? (
            <a
              className={classNames({[S.hasTitle]: props.title})}
              href="https://www.werkenvoorinternationaleorganisaties.nl/internationale-organisaties">
              Internationale Organisaties
            </a>
           ) : null
           }
           {props.parent && <NavLink to={'/'} className={S.hasTitle}>{props.parent}</NavLink>}
           {props.subParent && <NavLink to={'/account'} className={S.hasTitle}>{props.subParent}</NavLink>}
          {props.title && <span>{props.title}</span>}
        </div>
      </div>
    </div>
  </header>
)

export default Header
