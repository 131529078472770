import React, { Fragment} from 'react';

import {connect} from 'react-redux';
import {authService} from '../../services';
import {userActions} from "../../actions/auth.actions";
import * as F from "../../assets/styles/shared/forms.module.scss";
import * as S from './Account.module.scss';

import AccountWrapper from "./AccountWrapper";

class MFA extends React.Component {
  constructor(props) {
    super(props);
    this.state = {objectURL: '', code: ''};
  }

  componentDidMount() {
    authService.getQRImage()
    .then((res)=>  {
      return res.blob();
    })
    .then(res => {
      this.setState({objectURL: URL.createObjectURL(res)});
    }).catch(err => {
      console.log('Error fetching QR code: ', err.message)
    });
  }

  updateField(e){
    this.setState({code: e.target.value})
  }

  handleSubmit(e) {
     e.preventDefault();
     this.props.dispatch(userActions.enableMFA(this.state.code));
  }

  handleError(errors){
    return (
      Object.keys(errors)
        .map(er=> {
          return errors[er].map(status => {
            return (
              <div className={F.formError} key={status}>
              {status}
            </div>
            )
          })
        })
    )
  }

  render() {
    const {objectURL, code} = this.state;
    const {error} = this.props;
    return(
      <AccountWrapper 
        title="" 
        goBackTitle="Terug naar mijn profiel"
        goBackLink={"/account"}>
        {objectURL && 
          <div style={{textAlign: 'center', marginBottom: 20}}>
            <img alt="qr-code" src={objectURL} />
          </div>}
          
        <Fragment>
          <div className={S.info}>
            <p>We vragen je eenmalig een app te installeren en activeren om voortaan met twee-factor-authenticatie te kunnen inloggen op deze website.</p>
            <ul>
              <li>Installeer de Google Authenticator app op je telefoon.</li>
              <li>Open de app en kies 'BEGIN SETUP', vervolgens 'Scan barcode'.</li>
              <li>Scan de barcode en vul het gegenereerde tokennummer hier in.</li>
            </ul>
          </div>
            <form onSubmit={(e) => {this.handleSubmit(e) }}>
               {error &&  this.handleError(error.body)}
            <div className={F.formGroup}>
                <label htmlFor="code">Token</label>
                <input
                  className={F.formControl}
                  id="code"
                  type="text"
                  value={code}
                  name="code"
                  onChange={(e)=>this.updateField(e)}
                  placeholder="Token" />
              
            </div>
            <input
              className={F.submit}
              disabled={!code}
              type="submit"
              value="Verstuur"/>

        </form>
            </Fragment>

      </AccountWrapper>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    error: state.authentication.error,
    isRegistered: state.authentication.isRegistered
  }
}

export default connect(mapStateToProps)(MFA);

