import React from 'react';
import * as S from './Vacancy.module.scss';

import SiteSettings from '../../SiteSettings';
import Saved from '../Vacancies/Saved';
import Share from '../Share';

import {formatDate} from '../../helpers';

const Vacancy = props => {

  let vac, closing_date, publication_date, updated_date;
  if(props.vacancy._source) {
    vac = props.vacancy._source;
    closing_date = formatDate(vac.closing_date);
    publication_date = formatDate(vac.publication_date);
    updated_date = formatDate(vac.updated_date);
  }

  return(
    <div className={S.root}>
      <div className={S.inner}>
        <div className={S.wrapper}>
          <span
            className={S.goBack}
            onClick={props.handleGoBack}>
            Terug naar overzicht
          </span>
          <Saved
                user={props.user}
                id={vac.id}
                detailPage
            />
          <h1 className={S.title}>{vac.function_title}</h1>
          <div className={S.summary}>
            <div className={S.component}>
              <div className={S.label}>
                Organisatie:
              </div>
              <div className={S.text}>
                {vac.organisation_description ? (
                  <a
                    href={vac.organisation_description}
                    target="_blank"
                    rel="noopener noreferrer">
                    {vac.organisation_name}
                  </a>
                  ) : (
                    vac.organisation_name
                  )
                }
              </div>
            </div>
            <div className={S.component}>
              <div className={S.label}>
                Land/standplaats:
              </div>
              <div className={S.text}>
              {vac.country}, {vac.city}
              </div>
            </div>
            <div className={S.component}>
              <div className={S.label}>
              Sluitingsdatum:
              </div>
              <div className={S.text}>
              {closing_date && vac.closing_date ? closing_date : '-'}
              </div>
            </div>
            {
              SiteSettings.REACT_APP_SEARCH_PARAMS === "NCIF" &&
                <div className={S.component}>
                  <div className={S.label}>
                  Carri&egrave;reniveau:
                  </div>
                  <div className={S.text}>
                    {vac.work_think_level === 'Onbekend' ? '-' : vac.work_think_level}
                  </div>
                </div>
            }
            <div className={S.component}>
              <div className={S.label}>
              Rang/schaal:
              </div>
              <div className={S.text}>
              {vac.grade === 'Onbekend' ? '-' : vac.grade}
              </div>
            </div>
            <div className={S.component}>
              <div className={S.label}>
              Vakgebied:
              </div>
              <div className={S.text}>
                {vac.discipline[0] === 'Onbekend' ? '-' : vac.discipline.join(', ')}
              </div>
            </div>
            {
              SiteSettings.REACT_APP_SEARCH_PARAMS === "WbdEU" &&
                <div className={S.component}>
                  <div className={S.label}>
                  Type dienstverband:
                  </div>
                  <div className={S.text}>
                    {vac.contract_type === 'Onbekend' ? '-' : vac.contract_type}
                  </div>
                </div>
            }
          </div>
          <div className={S.summaryNoBg}>
            <div className={S.component}>
              <div className={S.label}>
              Vacaturenummer:
              </div>
              <div className={S.text}>
              {vac.vacancy_number ? vac.vacancy_number : '-'}
              </div>
            </div>
            <div className={S.component}>
              <div className={S.label}>
                Publicatiedatum:
              </div>
              <div className={S.text}>
                {vac.publication_date ? publication_date : '-'}
              </div>
            </div>
            <div className={S.component}>
              <div className={S.label}>
                Datum laatste wijziging:
              </div>
              <div className={S.text}>
                {vac.updated_date ? updated_date : '-'}
              </div>
            </div>
            {
              SiteSettings.REACT_APP_SEARCH_PARAMS === "WbdEU" &&
                <div className={S.component}>
                  <div className={S.label}>
                  Carri&egrave;reniveau:
                  </div>
                  <div className={S.text}>
                    {vac.work_think_level === 'Onbekend' ? '-' : vac.work_think_level}
                  </div>
                </div>
            }
            {
              vac.promo_text !== 'Onbekend' ? (
                <div className={S.promoText}
                  dangerouslySetInnerHTML={{__html: vac.promo_text}}>
                </div>
              ) : null
            }
            {
              vac.description !== 'Onbekend' ? (
                  <div className={S.vacancyText}
                  dangerouslySetInnerHTML={{__html: vac.description}}>
                </div>
              ) : null
            }
            <div className={S.linkWrapper}>
              {
                closing_date && vac.closing_date ? (
                  <div data-stickyrole="background">
                    Sluitingsdatum:&nbsp;{closing_date}
                  </div>
                ) : null
              }
              <a
                data-stickyrole="sticky"
                href={vac.url}
                className={S.link}
                target="_blank"
                rel="noopener noreferrer">
                Meer informatie en solliciteren
              </a>

              { vac.duplicates ?
                <a href={vac.url}
                    className={S.url}
                    target="_blank"
                    rel="noopener noreferrer">
                    {vac.url}
                </a> && vac.duplicates.map(function(url, i){
                          return (
                            <a
                              href={url}
                              className={S.url}
                              target="_blank"
                              rel="noopener noreferrer">
                              {url}
                            </a>
                          )
                        })
                : null
              }
            </div>
          </div>
          <div className={S.disclaimer}>
            <b>Disclaimer</b>
            <p>Vacatures worden iedere nacht automatisch samengesteld op basis van officiële publicaties. De informatie die beschikbaar wordt gemaakt is mogelijk niet altijd volledig.</p>
          </div>
          <div className={S.share}>
            <h3>Deel deze vacature</h3>
            <Share
              title={vac.function_title}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Vacancy;
