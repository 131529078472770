import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../reducers';
import {jwtMW} from './jwtMW';
import SiteSettings from '../SiteSettings';

const loggerMiddleware = createLogger();



let middleware = [jwtMW, thunkMiddleware];

if(SiteSettings.NODE_ENV !== 'production') {
    middleware = [...middleware, loggerMiddleware]
}

export const store = createStore(
    rootReducer,
    applyMiddleware(
        ...middleware
    )
);
