import React, {Fragment} from 'react';
import * as S from './Help.module.scss';
import saveIcon from '../../assets/images/save.svg';
import editIcon from '../../assets/images/edit.svg';

export const HelpNCIF = (props) => (
  <div className={S.root}>
    <div className={S.inner}>
      <h2>Gebruikershandleiding</h2>
      <p>
          Download hier de <a download href="./Gebruikershandleiding_Vacaturesite.pdf">gebruikershandleiding</a>
      </p>
      <h2>Veelgestelde vragen</h2>
      <HelpText />
    </div>
  </div>
);

export const HelpWbdEU = (props) => (
  <div className={S.root}>
      <div className={S.inner}>
        <h2>Gebruikershandleiding</h2>
        <p>Download hier de <a download href="./Gebruikershandleiding_Vacaturesite.pdf">gebruikershandleiding</a></p>
        <h2>Veelgestelde vragen</h2>
        <HelpText />
      </div>
  </div>
);


const HelpText = () => {
  return(
    <Fragment>
      <ol className={S.helpText}>
        <li>
          <h3>Hoe vind ik vacatures?</h3>
          <p>Op de vacaturepagina kun je zoeken naar vacatures. Je kunt gebruik maken van de filters in het linker menu om een selectie in de vacatures te maken en je kunt gerichte trefwoorden gebruiken in het zoekveld onderaan dit menu, zoals een functietitel,  een land of een stad. </p>
        </li>
        <li>
          <h3>Hoe kan ik mijn zoekopdracht verfijnen?</h3>
          <p>
          Nadat je een initiële zoekopdracht hebt gedaan op de vacaturepagina zie je aan de linkerkant van het zoekresultaat een kolom met criteria waarmee je jouw zoekopdracht kunt verfijnen. Klik op één of meer criteria om je zoekopdracht te verfijnen.
          </p>
        </li>

        <li>
          <h3> Hoe sla ik mijn zoekopdrachten op? </h3>
          <p>
          Door de juiste zoekopdracht aan te maken en op te slaan worden er vacatures aangeboden die beter bij je carrièrewensen passen. Bovendien kun je je abonneren op periodieke notificaties per mail waarin je op nieuwe of gewijzigde vacatures wordt gewezen die binnen je zoekopdracht passen.
Voor het opslaan van een zoekopdracht heb je een account nodig waarmee je inlogt op de site. Kies ‘Login’ en vul je e-mailadres en wachtwoord in of maak een profiel aan. Vervolgens volg je onderstaande stappen voor het opslaan van een zoekopdracht en het aanmaken van een periodieke mailnotificatie:

</p> <ul>
        <li>Log in op de vacaturesite.</li>
        <li>Maak een zoekopdracht met behulp van de filters en trefwoord(en)</li>
        <li>Ga na of je de juiste resultaten krijgt</li>
        <li>Verfijn je zoekopdracht eventueel nog met de filters aan de linkerkant van de pagina.</li>
        <li>Kies ‘Bewaar deze zoekopdracht’ linksboven.</li>
        <li>Geef de zoekopdracht een herkenbare naam.</li>
        <li>Kies of je, en hoe vaak je, updates van nieuwe en gewijzigde vacatures wilt ontvangen.</li>
        <li>Kies ‘Opslaan’.</li>
      </ul>
      <p>Zodra er vacatures beschikbaar zijn die voldoen aan je zoekopdracht ontvang je deze in jouw mailbox. Als je niet tevreden bent met het resultaat van de opgeslagen zoekopdracht kun je deze verwijderen of aanpassen door in te loggen op je profiel en naar ‘Opgeslagen zoekopdrachten’ te gaan. Hier kun je zoekopdrachten toevoegen, verwijderen en de mailfrequentie aanpassen.
      </p>
        </li>
        <li>
          <h3>Hoe wijzig ik mijn zoekopdracht?</h3>
          <p>
          Als je je bewaarde zoekopdracht inhoudelijk wilt wijzigen of bijvoorbeeld de frequentie van de notificaties wilt aanpassen kun je dit als volgt doen:
          <ul>
            <li>Login op de vacaturesite</li>
            <li>Open bovenin de linker kolom de ‘Opgeslagen zoekopdrachten’</li>
            <li>Klik op de zoekopdracht die je wilt aanpassen</li>
            <li>Wanneer je de inhoud van je zoekopdracht wilt aanpassen
              <ul>
                <li>Wijzig je nu de filters en/of trefwoorden</li>
                <li>Controleer je de resultaten</li>
                <li>En kies je het groene icoon <img src={saveIcon} width="15px" alt="Save"/> achter de naam van je zoekopdracht</li>
              </ul>
            </li>
            <li>Wanneer je de frequentie of de naam van de zoekopdracht wilt aanpassen, of je wilt de zoekopdracht verwijderen
              <ul>
                <li>Kies je het blauwe icoon <img src={editIcon} alt="Edit"/> achter de naam van je zoekopdracht</li>
                <li>En wijzig je de naam en/of frequentie van de notificaties</li>
                <li>Of kies je voor ‘Verwijderen’ en ‘OK’.</li>
              </ul>
            </li>

          </ul>
          </p>
        </li>
        <li>
          <h3>Zoeken naar een vacature zonder functietitel?</h3>
          <p>
          Dat kan door bij het zoeken gebruik te maken van de filters en niet van trefwoorden. Je kunt bijvoorbeeld zoeken op een bepaald vakgebied en/of het land van jouw keuze.
          </p>
        </li>
        <li>
          <h3>Hoe kan ik zoeken op vacaturenummer?</h3>
          <p>Je kunt een vacature niet terugvinden met het vacaturenummer dat getoond wordt in de vacaturetekst. Wel kun je dit nummer gebruiken mocht je contact op willen nemen met de werkgever voor meer informatie over de vacature.</p>
        </li>
        <li>
          <h3>Hoe vind ik mijn opgeslagen vacatures terug?</h3>
          <p>Je kunt de door jou opgeslagen vacatures oproepen door in de linker kolom ‘Toon alleen opgeslagen vacatures’ te kiezen.</p>
        </li>
        <li>
          <h3>Hoe kan ik een vacature doorsturen naar een vriend(in)?</h3>
          <p>
          Wanneer je een vacature opent zie je onderaan de pagina ‘Deel deze vacature’ staan. Hier kun je kiezen uit verschillende communicatiekanalen om je vriend(in) op de hoogte te stellen. Je maakt altijd gebruik van je eigen account of mailbox voor het doorsturen van een vacature.
          </p>
        </li>
        <li>
          <h3>
          Hoe kan ik meer informatie over een vacature verkrijgen en bekijken?
          </h3>
          <p>
          De vacatures die je op onze site ziet zijn vacatures bij verschillende internationale organisaties, die allemaal hun eigen sollicitatieprocedure kennen. Wanneer je een vacature aanklikt zie je op de detailpagina een knop ‘Meer informatie en solliciteren’. Je komt dan op de pagina waar de vacature oorspronkelijk door ons gevonden is. Daar vind je de benodigde informatie om op de betreffende vacature te reageren.
          </p>
        </li>
        <li>
          <h3>Mijn vraag staat hier niet tussen.</h3>
          <p>Heb je een vraag die hier niet beantwoord wordt? Kijk dan ook eens in de gebruikershandleiding,
            waarvan je de link hierboven vindt of gebruik ons contactformulier (<a href="https://www.werkenbijdeeu.nl/contact">https://www.werkenbijdeeu.nl/contact</a> / <a href="https://www.werkenvoorinternationaleorganisaties.nl/contact">https://www.werkenvoorinternationaleorganisaties.nl/contact</a> ) om je vraag te stellen. We helpen je graag.</p>
        </li>
      </ol>
    </Fragment>
  )
}
