import { userActions } from '../actions';
import {getToken } from '../actions/lib';

import jwt from 'jsonwebtoken';

export function jwtMW({ dispatch, getState }) {

    return (next) => (action) => {
        if (typeof action === 'function') {

            if (getState().authentication.loggedIn && getToken()) {
                var tokenExpiration = jwt.decode(getToken()).exp;
                let isTokenValid = (Date.now() / 1000 < tokenExpiration);
                if (tokenExpiration && !isTokenValid) {
                  return userActions.refreshToken(dispatch).then(() => next(action));
                }
            }
        }
        return next(action);
    };
}