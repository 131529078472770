import React from 'react';

import {connect} from 'react-redux';
import { userActions } from '../../actions';

const LogoutRoot = (props) => {
  props.dispatch(userActions.logout());
  return (
    <div>
      Logout
    </div>
  )
  }

export default connect()(LogoutRoot);