import React from 'react';

import {
    MultiRange, DataSearch, DatePicker
} from '@appbaseio/reactivesearch';

import NCIFFilter from '../Filter/Ncif.js'
import WbdEUFilter from '../Filter/Wbdeu.js'

import SiteSettings from '../../SiteSettings';
import {isEmptyObject} from '../../helpers';

import {connect} from 'react-redux';

import * as S from '../Vacancies/Vacancies.module.scss';

const BaseFilter = props => {
  const {
    handleSavedVacancies,
    defaultQuery,
    renderCount,
    organisations,
    noncountries,
    disciplines
  } = props;

  const transformWorkLevelData = data => {
    let list = [];
    if(data.find(item => item.key === 'Topmanager')) {
      list.push({
        doc_count: data.find(item => item.key === "Topmanager").doc_count,
        key:  "Topmanager"
      });
    }
    if(data.find(item => item.key === 'Senior Manager')) {
      list.push({
        doc_count: data.find(item => item.key === "Senior Manager").doc_count,
        key:  "Senior Manager"
      });
    }
    if(data.find(item => item.key === 'Middenmanager en Adviseur')) {
      list.push({
        doc_count: data.find(item => item.key === "Middenmanager en Adviseur").doc_count,
        key:  "Middenmanager en Adviseur"
      });
    }
    if(data.find(item => item.key === 'Teamleider en Expert')) {
      list.push({
        doc_count: data.find(item => item.key === "Teamleider en Expert").doc_count,
        key:  "Teamleider en Expert"
      });
    }
    if(data.find(item => item.key === 'Ervaren')) {
      list.push({
        doc_count: data.find(item => item.key === "Ervaren").doc_count,
        key:  "Ervaren"
      });
    }
    if(data.find(item => item.key === 'Professional')) {
      list.push({
        doc_count: data.find(item => item.key === "Professional").doc_count,
        key:  "Professional"
      });
    }
    return list;
  };


  const transformOrganisations = data => {
    let list = [];
    if(!isEmptyObject(organisations)) {
      let orgs = organisations[SiteSettings.REACT_APP_THEME];
      if(!isEmptyObject(orgs)) {
        orgs.forEach(element => {
          if (element === 'Onbekend') {
              // Don't show "Onbekend" in filters.
              return;
          }
          let count;
          let existingOrg = data.find(el => el.key === element);
          if(!isEmptyObject(existingOrg)) {
            count = existingOrg.doc_count
          }
          list.push({
            doc_count: count ? count : 0,
            key: element
          })
        });
        // Sort the list, ignoring the first parenthesised section.
        list.sort((a, b) => a.key.replace(/\(.*\)\s+/, '') > b.key.replace(/\(.*\)\s+/, ''))
        return list;
      } else {
        return data;
      }

    } else {
      return data;
    }
  }

  const transformDisciplines = data => {
    let list = [];
    if(disciplines) {
        disciplines.map(element => {
          if (element === 'Onbekend') {
              // Don't show "Onbekend" in filters.
              return null;
          }
          let count;
          let existingDiscipline = data.find(el => el.key === element);
          if(!isEmptyObject(existingDiscipline)) {
            count = existingDiscipline.doc_count
          }
          list.push({
            doc_count: count ? count : 0,
            key: element
          })
          return null;
        });
        return list;
    } else {
      return data;
    }
  }

  const transformCountries = data => {
    if(!isEmptyObject(noncountries)) {
      let results = [];
      noncountries.map(noncountry => {
        results = [
          ...results,
          ...data.filter(country =>
            country.key.localeCompare(noncountry, 'nl', { sensitivity: 'base' }) === 0
          )
        ]
      })
      results = [
        ...results,
        ...data.filter(country =>
          !results.find(element => element===country)
        )
      ]
      return results;
    }
  }

  return (
    <div>
      <DatePicker
        className={S.filter}
        componentId="DateSensor"
        dataField="publication_date"
        title="Publicatiedatum vanaf"
        filterLabel="Publicatiedatum vanaf"
        placeholder="datum vanaf"
        customQuery={
          function(value, props) {
            return {
              query: {
                range: {
                  publication_date: {
                    "gte": value
                  }
                }
              }
            }
          }
        }
      />

      <DataSearch
        className={S.filter}
        componentId="SearchFilter"
        title="Zoek op trefwoord"
        filterLabel="Trefwoord"
        autosuggest={false}
        debounce={250}
        dataField={[
            {
                field: "function_title",
                weight: 5,
            },
            {
                field: "country",
                weight: 3,
            },
            {
                field: "city",
                weight: 3,
            },
            {
                field: "organisation",
                weight: 1,
            }
        ]}
        placeholder=""
        URLParams
        innerClass={{
          input: S.searchInput
        }}
        defaultQuery={defaultQuery}
      />

      <MultiRange
        className={S.filter}
        componentId="SavedVacanciesFilter"
        dataField="savedVacancies"
        data={
          [{"value": true, "label": "Toon alleen opgeslagen vacatures"},]
        }
        title="Opgeslagen vacatures"
        showCheckbox={true}
        showFilter={true}
        filterLabel="Overzicht"
        customQuery={
          function(value, props) {
            if(value.length > 0) {
              if(value[0].value) {
                return {
                  query: {
                    bool: {
                      must: [
                        {
                          terms: {
                            id: handleSavedVacancies()
                          }
                        }
                      ]
                    }
                  }
                }
              }
            }
          }
        }
        URLParams
      />

      {
        SiteSettings.REACT_APP_THEME === 'wbdeu'
          ? <WbdEUFilter
              defaultQuery={defaultQuery}
              renderCount={renderCount}
              transformWorkLevelData={transformWorkLevelData}
              transformOrganisations={transformOrganisations}
              transformCountries={transformCountries}
              transformDisciplines={transformDisciplines}
            />
          : <NCIFFilter
              defaultQuery={defaultQuery}
              renderCount={renderCount}
              transformWorkLevelData={transformWorkLevelData}
              transformOrganisations={transformOrganisations}
              transformCountries={transformCountries}
              transformDisciplines={transformDisciplines}
            />
      }
    </div>
  )
}

BaseFilter.baseFiltersNames = [
    'DateSensor',
    'SavedVacanciesFilter'
]
BaseFilter.filtersNames = BaseFilter.baseFiltersNames.concat(
  SiteSettings.REACT_APP_THEME === 'wbdeu'
    ? WbdEUFilter.filtersNames
    : NCIFFilter.filtersNames
)

const mapStateToProps = state => ({
  organisations: state.organisations,
  noncountries: state.noncountries,
  disciplines: state.disciplines
});


export default connect(mapStateToProps)(BaseFilter)
