import React, {useState} from 'react';

import * as F from '../../assets/styles/shared/forms.module.scss';
import { connect } from 'react-redux';
import {userActions} from "../../actions";

import AccountWrapper from './AccountWrapper';

import {NavLink} from 'react-router-dom';

const Login = (props) => {

  const [form, setValues] = useState({
    code: '',
  });

  const updateField = e => {
    setValues({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let next = "/";
    if(document.location.search.includes('next=')){
      next = "/?" + document.location.search.substr(6);
    }
    if(form.code) {
      props.dispatch(userActions.loginMFA(form.code, next))
    }
  };

  const handleError = (errors) => {
    if(errors.detail){
        return (<div className={F.formError} key={errors.detail}>{errors.detail}</div>);
    }
    return (
      Object.keys(errors)
        .map(er=> {
          return errors[er].map(status => {
            return (
              <div className={F.formError} key={status}>
              {status}
            </div>
            )
          })
        })
    )
  }

  return (
    <AccountWrapper title="Authenticator code">
     {props.error &&  handleError(props.error.body)}
      <form onSubmit={handleSubmit}>
        <div className={F.formGroup}>
            <label htmlFor="code">Code</label>
            <input
              className={F.formControl}
              id="code"
              type="text"
              value={form.code}
              name="code"
              onChange={updateField}
              placeholder="Code" />
        </div>
        <input
          className={F.submit}
          disabled={!form.code}
          type="submit"
          value="Login"/>
          <div>
            Heeft u geen profiel? <NavLink to="/create">Dan kunt u hier een profiel maken</NavLink>
          </div>
      </form>
    </AccountWrapper>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    error: state.authentication.error
  }
}
export default connect(mapStateToProps)(Login);
