import React, {Fragment} from 'react';
import ReactDOM from 'react-dom';

import * as S from './Modal.module.scss';

const Modal = ({ children }) => (
  ReactDOM.createPortal(
    <Fragment>
      <div className={S.modalMask}></div>
      <div className={S.modalWrapper}>
        <div className={S.modalContent}>
          {children}
        </div>
      </div>
    </Fragment>,
    document.getElementById('modal-root')
  )
);

export default Modal; 