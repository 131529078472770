import { vacancyActionTypes } from "../actiontypes/vacancies";

export function noncountries(state = [], action) {

    if (typeof state === 'function') {
        state = state() || {};
    }

    switch (action.type) {
        case vacancyActionTypes.GET_NONCOUNTRIES_RESPONSE:
            console.log(action);
            if (action.error === true) return state;
            else return [...state, ...action.noncountries];
        default:
            return state;
    }
}

