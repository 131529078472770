import React from 'react';

const ScrollContext = React.createContext();


function useScroll() {
  const context = React.useContext(ScrollContext);
  if(!context) {
    throw new Error(`useScroll must be used within a ScrollContext`)
  }
  return context;
}


function ScrollProvider(props) {
  const [scroll, setScroll] = React.useState();
  const value = React.useMemo(() => [scroll, setScroll], [scroll])
  return <ScrollContext.Provider value={value} {...props} />
}

export {ScrollProvider, useScroll}