import SiteSettings from '../../SiteSettings';
import {useState} from 'react';
import {getCookie, setCookie} from '../../helpers';
import * as S from './Header.module.scss';


const Solong = (props) => {
    const [seenSolong, setSeenSolong] = useState(getCookie('solong'));
    const solong = (e) => {
        e.preventDefault()
        setSeenSolong(true)
        setCookie('solong', 'seen', 1)
    }
    return seenSolong ? false : (
      <div className={S.thanksforallthefish}>
        <button className={S.killme} onClick={solong}>x</button>
        {
            SiteSettings.REACT_APP_THEME === 'wbdeu'
            ? <p>
                <b>Let op!</b> Dit vacatureplatform gaat offline per 20 december 2024.<br/>
                <a href="https://www.linkedin.com/showcase/10277780">Volg WerkenbijdeEU op LinkedIn</a>:
                hier delen we regelmatig nieuws over EU-selectieprocedures en traineeships.
              </p>
            : <p>
                <b>Let op!</b> Dit vacatureplatform gaat offline per 20 december 2024.<br/>
                <a href="https://www.linkedin.com/company/75557142">Volg NCIF op LinkedIn</a>:
                hier delen we regelmatig interessante vacatures bij diverse internationale organisaties.
              </p>
        }
        </div>
    )
};

export default Solong
