import React, {useState} from 'react';

import * as F from '../../assets/styles/shared/forms.module.scss';
import { connect } from 'react-redux';
import {userActions} from "../../actions";

import AccountWrapper from './AccountWrapper';

const Forgot = (props) => {

  const [email, setemail] = useState('');

  const handleValue = (e) => {
    setemail(e.target.value);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if(email) {
      props.dispatch(userActions.requestPasswordLink(email))
    }
  };
  
  const handleError = errors => {
    return (
      Object.keys(errors)
        .map(er=> {
          return errors[er].map(status => {
            return (
              <div 
              className={F.formError}
              key={status}>
              {status}
            </div>
            )
          })
        })
    )
  }

  return (
    <AccountWrapper title="Wachtwoord vergeten">
      <form onSubmit={handleSubmit}>
        {props.error &&  handleError(props.error.body)}
          <div className={F.formGroup}>
            <label htmlFor="email">E-mailadres</label>
            <input
              className={F.formControl}
              id="email"
              type="email"
              value={email}
              name="email"
              onChange={handleValue}/>
          </div>
       
          <input
            className={F.submit}
            disabled={!email}
            type="submit"
            value="Wachtwoord link aanvragen"/>
       
      </form>
    </AccountWrapper>
  )
}

const mapStateToProps = state => {
  return {
    error: state.authentication.error
  }
}

export default connect(mapStateToProps)(Forgot);
