import { combineReducers } from 'redux';

import { authentication } from './auth';
import { vacancyReducer} from './vacancies';
import {savedSearches} from './savedsearches';
import {savedVacancies} from './savedVacancies';
import {pageSize} from './pageSize';
import {organisations} from './organisations';
import {noncountries} from './noncountries';
import {disciplines} from './disciplines';

const rootReducer = combineReducers({
    authentication,
    vacancyReducer,
    savedSearches,
    savedVacancies,
    pageSize,
    organisations,
    noncountries,
    disciplines
});

export default rootReducer;
