import React from 'react';
import Layout from '../../components/Layout';
import Create from '../../components/Account/Create';

const CreateRoot = props => {
  return (
    <Layout
      parent="Vacatures"
      title="Nieuw profiel">
        <Create />
    </Layout>
  )
}

export default CreateRoot;