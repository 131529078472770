export const vacancyActionTypes = {
    GET_VACANCY_REQUEST: 'GET_VACANCY_REQUEST',
    GET_VACANCY_RESPONSE: 'GET_VACANCY_RESPONSE',
    GET_VACANCY_FAILURE: 'GET_VACANCY_FAILURE',

    GET_ORGANISATIONS_REQUEST: 'GET_ORGANISATIONS_REQUEST',
    GET_ORGANISATIONS_RESPONSE: 'GET_ORGANISATIONS_RESPONSE',
    GET_ORGANISATIONS_FAILURE: 'GET_ORGANISATIONS_FAILURE',

    GET_NONCOUNTRIES_REQUEST: 'GET_NONCOUNTRIES_REQUEST',
    GET_NONCOUNTRIES_RESPONSE: 'GET_NONCOUNTRIES_RESPONSE',
    GET_NONCOUNTRIES_FAILURE: 'GET_NONCOUNTRIES_FAILURE',

    GET_DISCIPLINES_REQUEST: 'GET_DISCIPLINES_REQUEST',
    GET_DISCIPLINES_RESPONSE: 'GET_DISCIPLINES_RESPONSE',
    GET_DISCIPLINES_FAILURE: 'GET_DISCIPLINES_FAILURE'

}
