import React, {useState, Fragment, useEffect} from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';

import {withRouter} from 'react-router-dom';
import Modal from '../Modal';
import * as S from './SavedSearches.module.scss';
import {savedsearchActions} from '../../actions';
import SavedSearch from './SavedSearch';
import Item from './Item';
import LoginFirst from "./LoginFirst";
import SiteSettings from '../../SiteSettings';

const SavedSearches = props => {
  const [isShown, setIsShown] = useState(false);
  const [toggleSearches, settoggleSearches] = useState(false);
  const [editItem, seteditItem] = useState();
  const [selectedItem, setSelectedItem] = useState('');
  const [showSaveBtn, setShowSaveBtn] = useState(false);

  const hide = () =>  {
    document.getElementsByTagName("body")[0].style.overflow = '';
    seteditItem();
    setIsShown(false);
  };

  const show = () => {
    document.getElementsByTagName("body")[0].style.overflow = 'hidden';
    setIsShown(true);
  }

  const toggleSearch = () => {
    settoggleSearches(!toggleSearches);
  }

  const handleSave = (data) => {
      let urlParams = window.location.search;
      let searchData = {
        filter_name: data.description,
        query: props.rawQuery,
        filter: urlParams,
        periodicity: data.update,
        site: SiteSettings.REACT_APP_THEME
      }
      props.dispatch(savedsearchActions.add(searchData))
        .then(res => {
          hide();
        });
  }

  const handleUpdate = (id, data) => {
    let searchData = {
      filter_name: data.description,
      periodicity: data.update,
      query: editItem.query,
      filter: editItem.filter,
    }
    props.dispatch(savedsearchActions.update(id, searchData))
        .then(res => {
          hide();
        });
  }

  const handleDelete = (id) => {
    if(window.confirm('Weet u het zeker?')) {
      props.dispatch(savedsearchActions.remove(id, editItem.periodicity))
        .then(res => {
          hide();
        })
    }
  }

  const handleEdit = (item) => {
    seteditItem(item);
    show();
  }

  const goTo = (id, url) => {
    setSelectedItem(id);
    setShowSaveBtn(false);
    props.handleRouteCHange(url);
  }

  const handleSaveCurrent = (item) => {
    let urlParams = window.location.search;
    let searchData = {
      filter_name: item.filter_name,
      periodicity: item.update,
      query: props.rawQuery,
      filter: urlParams,
    }
    props.dispatch(savedsearchActions.update(item.id, searchData))
        .then(res => {
        });
  }

  useEffect(() => {
    let winLocation = window.location.search;
    if(selectedItem && props.savedSearches) {
      if(props.savedSearches.length > 0) {
        let selected = props.savedSearches.filter(item => item.id === selectedItem);
        if(selected[0].filter !== winLocation) {
          setShowSaveBtn(true);
        } else {
          setShowSaveBtn(false);
        }
      }
      if(!winLocation) {
        setShowSaveBtn(false);
        setSelectedItem('');
      }
    }
  }, [
    selectedItem,
    props.savedSearches
  ]);

  return(
    <div className={S.root}>
      <div className={S.link} onClick={show}>Bewaar deze zoekopdracht</div>
      { (!props.isLoggedIn && isShown) && <Modal><LoginFirst hide={hide} /></Modal> }

      { (props.isLoggedIn && isShown) && <Modal><SavedSearch
                           item={editItem && editItem}
                           handleSave={handleSave}
                           handleUpdate={handleUpdate}
                           handleDelete={handleDelete}
                           hide={hide}/>
                  </Modal>}
      {props.savedSearches.length > 0 ? (
        <Fragment>
          <div
            onClick={toggleSearch}
            className={classNames(S.link, S.dropdown, {[S.open]: toggleSearches})}>Opgeslagen zoekopdrachten <span>({props.savedSearches.length})</span></div>
          {
            toggleSearches && props.savedSearches
              .map((item, i) => <Item
                                  key={item.id}
                                  item={item}
                                  goTo={goTo}
                                  handleEdit={handleEdit}
                                  selectedItem={selectedItem}
                                  showSaveBtn={showSaveBtn}
                                  handleSaveCurrent={handleSaveCurrent}
                                />)}

        </Fragment>
      ) : null
    }
    </div>
  )
}

const mapStateToProps = state => {
  return {
    savedSearches: state.savedSearches.items,
    addedItem: state.savedSearches.addedItem
  }
}

export default  withRouter(connect(mapStateToProps)(SavedSearches));
