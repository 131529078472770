import { vacancyActionTypes } from "../actiontypes/vacancies";

export function disciplines(state = [], action) {

    if (typeof state === 'function') {
        state = state() || {};
    }

    switch (action.type) {
        case vacancyActionTypes.GET_DISCIPLINES_RESPONSE:
            if (action.error === true) return state;
            else return [...state, ...action.disciplines];
        default:
            return state;
    }
}
