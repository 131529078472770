import SiteSettings from '../SiteSettings';

import {
  fetchWrapper,
} from '../actions/lib';

export const savedSearchService = {
  getAll,
  add,
  update,
  remove,
};


const api_url = SiteSettings.REACT_APP_API_URL;
const site = SiteSettings.REACT_APP_THEME

function getAll() {
  return fetchWrapper(`${api_url}/user/searchfilter/?site=${site}`, {
      method: 'GET',
  }).then(res => {
    return Promise.resolve(res);
  }).catch(error => {
    return Promise.reject(error);
  });
}

async function add(data) {
  return fetchWrapper(`${api_url}/user/searchfilter/`, {
      method: 'POST',
      body: JSON.stringify({
        ...data
      })
    }).then(res => {
      return Promise.resolve(res)
    }).catch(error => {
      return Promise.reject(error)
    })
}

async function update(id, data) {
  return fetchWrapper(`${api_url}/user/searchfilter/${id}/`, {
      method: 'PATCH',
      body: JSON.stringify({
        ...data
      })
    }).then(res => {
      return Promise.resolve(res)
    }).catch(error => {
      return Promise.reject(error)
    })
}

async function remove(id) {
  return fetchWrapper(`${api_url}/user/searchfilter/${id}/`, {
        method: 'DELETE',
    }).then(res => {
      return Promise.resolve()
    }).catch(error => {
      return Promise.reject(error)
    })
}
