import { savedVacanciesActionTypes } from '../actiontypes/savedVacancies';

const initialState = {
  vacancies: []
};

export function savedVacancies(state = initialState, action) {
  
  switch (action.type) {
    case savedVacanciesActionTypes.GET_SAVED_VACANCIES_REQUEST:
      if(localStorage.getItem('savedVacancies')) {
        return {
          vacancies: JSON.parse(localStorage.getItem('savedVacancies'))
        };
      } else {
        return {
          vacancies: state.vacancies
        };
      }
     

    case savedVacanciesActionTypes.ADD_SAVED_VACANCY_SUCCESS:
      localStorage.setItem('savedVacancies', JSON.stringify([...state.vacancies, action.id]))
      return {
        ...state,
        vacancies: [...state.vacancies, action.id]
      };

    case savedVacanciesActionTypes.REMOVE_SAVED_VACANCY_SUCCESS:
      localStorage.setItem('savedVacancies',  JSON.stringify(state.vacancies.filter(vacancy => vacancy !== action.id)))
      return {
        ...state,
        vacancies: state.vacancies.filter(vacancy => vacancy !== action.id)
      };

    default:
      return state
  }
}
