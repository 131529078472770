import React, { Fragment} from 'react';
import {  Link } from 'react-router-dom';
import * as F from '../../assets/styles/shared/forms.module.scss';
import { connect } from 'react-redux';

const LoginFirst = (props) => {
  return (

    <Fragment>
        <h2 className={F.title}>Login om een zoekopdracht te bewaren</h2>
        <div>
          Om een zoekopdracht op te slaan dient u eerst in te loggen.<br/><br/>
        </div>
      <Link to={"/login?next="+document.location.search.substr(1)}>
      <input
          className={F.submit}
          type="submit"
          value="Inloggen"
      />
      </Link>
          <input
            className={F.seconday}
            type="button"
            value="Annuleren"
            onClick={props.hide}/>

    </Fragment>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {}
}
export default connect(mapStateToProps)(LoginFirst);
