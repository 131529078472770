import React from 'react';
import { connect } from 'react-redux';

import Layout from '../../components/Layout';
import Vacancies from '../../components/Vacancies';

const Index = props => {
  return(
    <Layout title="Vacatures">
      <Vacancies user={props.authed} />
    </Layout>);
}

export default connect()(Index)
