import React from 'react';

import {NavLink} from 'react-router-dom';

import * as F from '../../assets/styles/shared/forms.module.scss';
import {userActions} from '../../actions/auth.actions';
import AccountWrapper from './AccountWrapper';
import {connect} from "react-redux";

const Account = (props) => {
    let user = props.authentication.user;
    let mfaAction;

    const disableMFA = (e) => {
        e.preventDefault();
        props.dispatch(userActions.disableMFA())
    };

    if(user.mfa_methods.length > 0) {
        if(user.mfa_methods[0].is_primary) {
            mfaAction = <span className={F.link} onClick={disableMFA}>Twee-factor-authenticatie uitschakelen</span>
        } else {
            mfaAction = <NavLink to="/setupmfa">Twee-factor-authenticatie instellen</NavLink>;
        }
    } else {
        mfaAction = <NavLink to="/setupmfa">Twee-factor-authenticatie instellen</NavLink>;
    }

    return (
        <AccountWrapper title="Mijn profiel">
            <div className={F.formGroup}>
                <label>E-mailadres</label>
                {user.username}
            </div>
            <div className={F.formGroup}>
                <label>Wachtwoord</label>
                <NavLink to="/password-reset">Wachtwoord wijzigen</NavLink>
            </div>
            <div className={F.formGroup}>
                <label>Twee-factor-authenticatie</label>
                {mfaAction}
            </div>
            <div className={F.formGroup}>
                <NavLink  to='/logout'>Uitloggen</NavLink>
            </div>
            <div className={F.formGroup}>
                <NavLink to="/remove" className={F.remove}>Profiel
                    verwijderen</NavLink>
            </div>

        </AccountWrapper>
    )
};

const mapStateToProps = state => {
  return {
    error: state.authentication.error,
    isRegistered: state.authentication.isRegistered
  }
}

export default connect(mapStateToProps)(Account);

