export const userActionTypes = {
    LOGIN_USER_REQUEST: 'LOGIN_USER_REQUEST',
    LOGIN_USER_RESPONSE: 'LOGIN_USER_RESPONSE',
    LOGOUT_USER: 'LOGOUT_USER',
    LOGIN_REQUIRE_MFA: 'LOGIN_REQUIRE_MFA',
    LOGIN_MFA_REQUEST: 'LOGIN_MFA_REQUEST',

    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    TOKEN_REQUEST: 'USERS_TOKEN_REQUEST',
    TOKEN_SUCCESS: 'USERS_TOKEN_SUCCESS',
    TOKEN_FAILURE: 'USERS_TOKEN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',

    PASSWORD_RESET_REQUEST: 'PASSWORD_RESET_REQUEST',
    PASSWORD_RESET_SUCCESS: 'PASSWORD_RESET_SUCCESS',
    PASSWORD_RESET_FAILURE: 'PASSWORD_RESET_FAILURE',

    ADD_FAVORITE_REQUEST: 'ADD_FAVORITE_REQUEST',
    ADD_FAVORITE_SUCCESS: 'ADD_FAVORITE_SUCCESS',
    ADD_FAVORITE_FAILURE: 'ADD_FAVORITE_FAILURE',

    REMOVE_FAVORITE_REQUEST: 'REMOVE_FAVORITE_REQUEST',
    REMOVE_FAVORITE_SUCCESS: 'REMOVE_FAVORITE_SUCCESS',
    REMOVE_FAVORITE_FAILURE: 'REMOVE_FAVORITE_FAILURE',

    PASSWORD_FORGOT_REQUEST: 'PASSWORD_FORGOT_REQUEST',
    PASSWORD_FORGOT_SUCCESS: 'PASSWORD_FORGOT_SUCCESS',
    PASSWORD_FORGOT_FAILURE: 'PASSWORD_FORGOT_FAILURE',

    SET_PASSWORD_CONFIRM_REQUEST: 'SET_PASSWORD_CONFIRM_REQUEST',
    SET_PASSWORD_CONFIRM_SUCCESS: 'SET_PASSWORD_CONFIRM_SUCCESS',
    SET_PASSWORD_CONFIRM_FAILURE: 'SET_PASSWORD_CONFIRM_FAILURE',

    ACTIVATE_REQUEST: 'ACTIVATE_REQUEST',
    ACTIVATE_SUCCESS: 'ACTIVATE_SUCCESS',
    ACTIVATE_FAILURE: 'ACTIVATE_FAILURE',

    ENABLE_MFA_REQUEST: 'ENABLE_MFA_REQUEST',
    ENABLE_MFA_SUCCESS: 'ENABLE_MFA_SUCCESS',
    ENABLE_MFA_FAILURE: 'ENABLE_MFA_FAILURE',

    DISABLE_MFA_REQUEST: 'DISABLE_MFA_REQUEST',
    DISABLE_MFA_SUCCESS: 'DISABLE_MFA_SUCCESS',
    DISABLE_MFA_FAILURE: 'DISABLE_MFA_FAILURE',


    DONE_REFRESHING_TOKEN: 'DONE_REFRESHING_TOKEN',
    REFRESHING_TOKEN: 'REFRESHING_TOKEN',

    VACANCY_PAGE_SIZE: 'VACANCY_PAGE_SIZE'

};
