export function dateToString(date) {
    return `${(date.getFullYear())}-${(date.getMonth() + 1)}-${date.getDate()}`;
}

/**
 * Test whether given input object is an empty object. ({})
 * @param obj
 * @returns {boolean}
 */
export function isEmptyObject(obj) {
    for(const prop in obj) {
        if(obj.hasOwnProperty(prop))
            return false;
    }
    return true;
}


export function Authorization(user, roles) {
    let userRoles = user.roles;
    if(roles.some(role=> userRoles.includes(role))) {
        return true;
    }
}

export const formatDate = (date) => {
    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    var today  = new Date(date);
    return today.toLocaleDateString("nl-NL", options);
};


export const getCookie = (name) => {
    let cookieValue = null
    if (document.cookie && document.cookie != '') {
        const cookies = document.cookie.split(';')
        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i].trim()
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) == name + '=') {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
                break
            }
        }
    }
    return cookieValue
}
export function setCookie(name, value, days) {
    const d = new Date()
    d.setTime(d.getTime() + (days*24*60*60*1000))
    let expires = "expires="+ d.toUTCString()
    document.cookie = `${name}=${value}; expires=${d.toUTCString()}; path=/`
}
