import React from 'react';

import classNames from 'classnames';

import * as S from './Loader.module.scss';

const Loader = props => {
  return (
    <div className={classNames(S.root, {[S.inline]: props.inline})}>
      <div className={S.spinRoot}>
        <div className={classNames(S.spinChild, S.spinChild1)}></div>
        <div className={classNames(S.spinChild, S.spinChild2)}></div>
        <div className={classNames(S.spinChild, S.spinChild3)}></div>
        <div className={classNames(S.spinChild, S.spinChild4)}></div>
        <div className={classNames(S.spinChild, S.spinChild5)}></div>
        <div className={classNames(S.spinChild, S.spinChild6)}></div>
        <div className={classNames(S.spinChild, S.spinChild7)}></div>
        <div className={classNames(S.spinChild, S.spinChild8)}></div>
        <div className={classNames(S.spinChild, S.spinChild9)}></div>
        <div className={classNames(S.spinChild, S.spinChild10)}></div>
        <div className={classNames(S.spinChild, S.spinChild11)}></div>
        <div className={classNames(S.spinChild, S.spinChild12)}></div>
      </div>
    </div>
  )
}

export default Loader;