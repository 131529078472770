import { savedSearchActionTypes } from '../actiontypes/savedSearch';

const initialState = {
  items: []
};

export function savedSearches(state = initialState, action) {
  switch (action.type) {
    case savedSearchActionTypes.GET_SAVED_SEARCHES_SUCCESS:
      return {
        items: action.items
      };

    case savedSearchActionTypes.GET_SAVED_SEARCHES_FAILURE:
      return {
        error: action.error
      };

    case savedSearchActionTypes.ADD_SAVED_SEARCH_SUCCESS:
      return {
        ...state,
        items: [...state.items, action.item]
      };

    case savedSearchActionTypes.ADD_SAVED_SEARCH_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case savedSearchActionTypes.REMOVE_SAVED_SEARCH_SUCCESS:
      return {
        ...state,
        items: state.items.filter(item => item.id !== action.id)
      };

    case 'LOGOUT':
        return initialState

    default:
      return state
  }
}
