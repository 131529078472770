import React from 'react';
import * as S from './Vacancy.module.scss';
import moment from 'moment'

import SiteSettings from '../../SiteSettings';

import {formatDate} from '../../helpers';

import {NavLink} from 'react-router-dom';

import Saved from './Saved';

const Vacancy = (props) => {

    let options;

    if(SiteSettings.REACT_APP_SEARCH_PARAMS === "NCIF") {
      options = {
        organisation: {
            display: 'Organisatie:',
        },
        location: {
            display: 'Land/standplaats:'
        },
        endDate: {
            display: "Sluitingsdatum:"
        },
        work_think_level: {
            display: 'Carrièreniveau:'
        },
        grade: {
            display: 'Rang/schaal:'
        },
        discipline: {
            display: "Vakgebied:"
        }
      };

    } else if (SiteSettings.REACT_APP_SEARCH_PARAMS === "WbdEU") {
      options = {
        organisation: {
            display: 'Organisatie:',
        },
        location: {
            display: 'Land/standplaats:'
        },
        endDate: {
            display: "Sluitingsdatum:"
        },
        work_think_level: {
            display: 'Carrièreniveau:'
        },
        discipline: {
            display: "Vakgebied:"
        },
        contract_type: {
            display: "Type dienstverband:"
        }
      };
    }

    const isInvalidOption = (value) => {
        return value === 'Onbekend' || value === 'UNKNOWN' || !value;
    }

    const renderItem = (option, value) => {
        let display;
        if(isInvalidOption(value)) {
            display = '-';
        } else {
            if(Array.isArray(value)) {
                display = value.map(v => renderItem(option, v)).join(', ')
            } else if(option === 'endDate'){
                display = formatDate(value);
            } else {
                display = value;
            }
        }
        return display;
    };

    const getDateAlert = (value) => {
        let todayDate = moment();
        let closingDate = moment(value).set({
          hour: 23,
          minute:59,
          second: 59
        });
        let days = closingDate.diff(todayDate, 'days');

        let text = null;

        if (days === 0) {
          text = 'Vandaag';
        } else if (days === 1) {
          text = 'Nog ' + days + ' dag';
        } else {
          text = 'Nog ' + days + ' dagen';
        }

        return (
            days >= 0 && days <= 7 ? text : null
        )
    }

    return(
        <div className={S.root}>
            <h2 className={S.title}>
                <NavLink
                    onClick={props.handleScrollPos}
                    to={`/vacancy/${props.id}`}>{props.title}</NavLink>
            </h2>
            <Saved
                user={props.user}
                id={props.id}
                refreshRefClick={props.refreshRefClick}
            />
            {
                Object.keys(options)
                    .map((el, i) => {
                        return(
                            <div
                                key={i}
                                className={S.component}>
                                <div className={S.label}>
                                    {options[el].display}
                                </div>
                                <div className={S.text}>
                                    {renderItem(el, props[el])}
                                    {
                                        el === 'endDate' && !isInvalidOption(props[el]) && getDateAlert(props[el])
                                        ?
                                        <div className={S.closingDateAlert}>
                                            {getDateAlert(props[el])}
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        )
                    })
            }
        </div>
    )
}

export default Vacancy;
