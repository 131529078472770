import React from 'react';
import Layout from '../../components/Layout';
import Account from '../../components/Account';
import {connect} from 'react-redux';

import {isEmptyObject} from '../../helpers';

const AccountRoot = props => {
    return (
      <Layout 
        parent="Vacatures"
        title="Mijn profiel">
      {!isEmptyObject(props.authentication) &&  <Account 
          authentication={props.authentication} 
         />}  
      </Layout>
    )
}


const mapStateToProps = state => {
  return {
    authentication: state.authentication
  }
}
export default connect(mapStateToProps)(AccountRoot);