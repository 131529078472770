import React from 'react';
import Layout from '../../components/Layout';
import Login from '../../components/Account/Login';
import LoginMFA from '../../components/Account/LoginMFA';


export class LoginMFARoot extends React.Component {
    render() {
        return (
            <Layout 
                parent="Vacatures"
                title="Login">
                <LoginMFA />
            </Layout>
        )
    }
}

class LoginRoot extends React.Component {

    render() {
        return (
            <Layout
                parent="Vacatures"
                title="Login">
                <Login />
            </Layout>
        )
    }
}

export default LoginRoot;

