import React from 'react';

import * as S from './Pagination.module.scss';

const getStartPage = (totalPages, currentPage) => {
  const midValue = parseInt(totalPages / 2, 10);
	const start = currentPage - midValue;
	return start > 1 ? start : 2;
};

const Pagination = data => {
  const start = getStartPage(data.pages, data.currentPage);
    const pages = [];

    const onPrevPage = (e) => {
      e.preventDefault();
      if (data.currentPage) {
        data.setPage(data.currentPage - 1);
      }
    };

    const onNextPage = (e) => {
      e.preventDefault();
      if (data.currentPage < data.totalPages - 1) {
        data.setPage(data.currentPage + 1);
      }
    };

    if (start <= data.totalPages) {
      const totalPagesToShow
        = data.pages < data.totalPages ? start + (data.pages - 1) : data.totalPages + 1;

      for (let i = start; i < totalPagesToShow; i += 1) {
        const primary = data.currentPage === i - 1;
        const innerClassName = S.button;
        const className
          = innerClassName || primary ? `${innerClassName} ${primary ? S.active : ''}` : null;
        const pageBtn = (
          <a
            className={className}
            primary={primary.toString()}
            key={i - 1}
            tabIndex="0"
            onClick={(e) => {
              e.preventDefault();
              data.setPage(i - 1);
            }}
            href={`?${data.fragmentName}=${i}`}
          >
            {i}
          </a>
        );
        if (i <= data.totalPages + 1) {
          pages.push(pageBtn);
        }
      }
    }

    if (!data.totalPages) {
      return null;
    }

    const innerClassName = S.button;
    const primary = data.currentPage === 0;
    const className
      = innerClassName || primary ? `${innerClassName} ${primary ? S.active : ''}` : null;

    let prevHrefProp = {};
    let nextHrefProp = {};

    if (data.currentPage >= 1) {
      prevHrefProp = {
        href: `?${data.fragmentName}=${data.currentPage}`,
        rel: 'prev',
      };
    }

    if (data.currentPage < data.totalPages - 1) {
      nextHrefProp = {
        href: `?${data.fragmentName}=${data.currentPage + 2}`,
        rel: 'next',
      };
    }
    
    const RenderEnd = () => {
      let totalPage = data.totalPages - 1;
      if(data.currentPage !== totalPage) {
        return (
          <span>
          ...
            <a 
              className={S.button}
              href={`?${data.fragmentName}=${totalPage}`}
              onClick={(e) => {  e.preventDefault(); data.setPage(totalPage)}}>{data.totalPages}</a>
          </span>
        )
        } else {
          return(
            <span></span>
          );
        }
    }
    return (
      <div className={S.pagination}>
        <a
          className={S.button}
          disabled={data.currentPage === 0}
          primary={primary.toString()}
          onClick={(e) => {
            e.preventDefault();
            data.setPage(0);
          }}
          tabIndex="0"
          href={`?${data.fragmentName}=1`}
        >
          {`<<`}
        </a>
        <a
          className={S.button}
          disabled={data.currentPage === 0}
          onClick={onPrevPage}
          tabIndex="0"
          {...prevHrefProp}
        >
          {`<`}
        </a>
        {
          <a
            className={className}
            primary={primary.toString()}
            onClick={(e) => {
              e.preventDefault();
              data.setPage(0);
            }}
            tabIndex="0"
            href={`?${data.fragmentName}=1`}
          >
            1
          </a>
        }
        {data.currentPage >= data.pages ? <span>...</span> : null}
        {pages}
        {data.totalPages > 5 && <RenderEnd />}
        <a
          className={S.button}
          disabled={data.currentPage >= data.totalPages - 1}
          onClick={onNextPage}
          tabIndex="0"
          {...nextHrefProp}
        >
          {`>`}
        </a>
        <a
          className={S.button}
          primary={primary.toString()}
          disabled={data.currentPage >= data.totalPages - 1}
          onClick={(e) => {
            e.preventDefault();
            data.setPage(data.totalPages - 1);
          }}
          tabIndex="0"
          href={`?${data.fragmentName}=${data.totalPages}`}
        >
          {`>>`}
        </a>
      </div>
    );
}

export default Pagination;
