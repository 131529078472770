import React, {useEffect, useState} from 'react';
import Layout from '../../components/Layout';
import {withRouter} from 'react-router-dom';
import {NavLink} from 'react-router-dom';

import SiteSettings from '../../SiteSettings';
import Vacancy from '../../components/Vacancy';

import {vacancyService} from '../../services';

const VacancyRoot = props => {

  const [vacancy, setvacancy] = useState('');
  const [status, setstatus] = useState("initial");

  const handleGoBack = () => {
    if(props.history.length > 2) {
      window.history.back();
    } else {
      props.history.push('/');
    }
  };

  useEffect(() => {
    if(props.match.params.id && !vacancy) {
      setstatus("loading")
      vacancyService.getVacancy(props.match.params.id)
      .then(res=> {
        if (res.hits.total.value === 0) {
          setstatus("notfound")
        } else {
          setvacancy(res.hits.hits[0])
          setstatus("succes")
          document.title = `${res.hits.hits[0]._source.function_title} - ${SiteSettings.REACT_APP_TITLE}`
        }
      })
      .catch(res=> {
          setstatus("failed")
      })
    }
  }, [props.match.params.id, vacancy])

  return (
    <Layout
      parent="Vacatures"
      title="Vacature">
    {
      vacancy ? (
        <Vacancy
          vacancy={vacancy}
          user={props.authed}
          handleGoBack={handleGoBack}
        />
      ) : (
        <div className={`vacancyloading vacancyloading-${status}`}>
        {
          (status === "initial") ? (
            <p> De vacature wordt opgehaald. </p>
          ) : (status === "loading") ? (
            <p> De vacature wordt opgehaald. </p>
          ) : (status === "failed") ? (
            <p> Er is iets misgegaan. </p>
          ) : (status === "notfound") ? (
            <>
              <p>
              Deze vacature kan niet (meer) gevonden worden.
              Mogelijk is deze verlopen.
              </p>
              <p>
              <NavLink to="/">Start hier een nieuwe zoekopdracht</NavLink>
              </p>
            </>
          ) : null
        }
        </div>
      )
    }
    </Layout>
  )
}

export default withRouter(VacancyRoot);
