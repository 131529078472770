export const savedSearchActionTypes = {
  GET_SAVED_SEARCHES_REQUEST: 'GET_SAVED_SEARCHES_REQUEST',
  GET_SAVED_SEARCHES_SUCCESS: 'GET_SAVED_SEARCHES_SUCCESS',
  GET_SAVED_SEARCHES_FAILURE: 'GET_SAVED_SEARCHES_FAILURE',

  ADD_SAVED_SEARCH_REQUEST: 'ADD_SAVED_SEARCH_REQUEST',
  ADD_SAVED_SEARCH_SUCCESS: 'ADD_SAVED_SEARCH_SUCCESS',
  ADD_SAVED_SEARCH_FAILURE: 'ADD_SAVED_SEARCH_FAILURE',

  UPDATE_SAVED_SEARCH_REQUEST: 'UPDATE_SAVED_SEARCH_REQUEST',
  UPDATE_SAVED_SEARCH_SUCCESS: 'UPDATE_SAVED_SEARCH_SUCCESS',
  UPDATE_SAVED_SEARCH_FAILURE: 'UPDATE_SAVED_SEARCH_FAILURE',

  REMOVE_SAVED_SEARCH_REQUEST: 'REMOVE_SAVED_SEARCH_REQUEST',
  REMOVE_SAVED_SEARCH_SUCCESS: 'REMOVE_SAVED_SEARCH_SUCCESS',
  REMOVE_SAVED_SEARCH_FAILURE: 'REMOVE_SAVED_SEARCH_FAILURE',
  LOGOUT: 'LOGOUT'
}
