import React from 'react';
import Layout from '../../components/Layout';
import {connect} from 'react-redux';

import SetPassword from '../../components/Account/SetPassword'

const SetPasswordRoot = props => {
    return (
      <Layout 
        title="Wachtwoord instellen">
        <SetPassword
          />
      </Layout>
    )
}

export default connect()(SetPasswordRoot);