import SiteSettings from '../SiteSettings';

import {
  loggedIn,
  setProfile,
  getProfile,
  setToken,
  getToken,
  fetchWrapper,
  getEphemeralToken,
  getRefreshToken
} from '../actions/lib';

export const authService = {
  login,
  loginMFA,
  loggedIn,
  setToken,
  getToken,
  setProfile,
  getProfile,
  register,
  activate,
  setPassword,
  passwordReset,
  addFavorite,
  removeFavorite,
  logout,
  getMe,
  setPasswordConfirm,
  getQRImage,
  enableMFA,
  disableMFA,
  refreshAccessToken,
  removeAccount
};


const api_url = SiteSettings.REACT_APP_API_URL;

function getQRImage() {
    return fetch(`${api_url}/auth/ga/qr/`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
        }
    });
}

function enableMFA(code) {
    return fetchWrapper(`${api_url}/auth/app/activate/confirm/`, {
        method: 'POST',
        body: JSON.stringify({code: code})
    })
}

function disableMFA(code) {
    return fetchWrapper(`${api_url}/auth/app/deactivate/`, {
        method: 'POST'
    }).then(res => {
        getMe();
    });
}

function login(username, password) {
  return fetchWrapper(`${api_url}/auth/login/`, {
        method: 'POST',
        body: JSON.stringify({
            username,
            password
        })
    }).then(res => {
      setToken(res);
      return Promise.resolve(res);
    }).catch(error => {
      return Promise.reject(error);
    });
}

function loginMFA(code) {
    const ephemeral_token = getEphemeralToken();
    return fetchWrapper(`${api_url}/auth/login/code/`, {
        method: 'POST',
        body: JSON.stringify({
            ephemeral_token,
            code
        })
    }).then(res => {
        setToken(res);
        return getMe();
    }).catch(error => {
        return Promise.reject(error);
    })
}

function getMe(){
    return fetchWrapper(`${api_url}/auth/users/me/`, {
        method: 'GET'
    }).then(res => {
        setProfile(res);
        return Promise.resolve(res)
    }).catch(error => {
      return Promise.reject(error)
    })
}



function logout() {

  // return fetchWrapper(`${api_url}/auth/logout/`, {
      // method: 'POST',
  // }).then(res => {
    sessionStorage.removeItem('access');
    sessionStorage.removeItem('profile');
    return Promise.resolve()
  // })
}

function register(user) {
  return fetchWrapper(`${api_url}/auth/users/`, {
      method: 'POST',
      body: JSON.stringify({
        ...user
      })
  }).then(res => {
    return Promise.resolve(res)
  }).catch(error => {
    return Promise.reject(error)
  })
}

function activate(form) {
  return fetchWrapper(`${api_url}/auth/users/activation/`, {
      method: 'POST',
      body: JSON.stringify({
        ...form
      })
  }).then(res => {
    return Promise.resolve(res)
  }).catch(error => {
    return Promise.reject(error)
  })
}

async function addFavorite(vacancyId) {
  return fetchWrapper(`${api_url}/user/favourite/${vacancyId}/`, {
        method: 'POST',
    }).then(res => {
      return Promise.resolve(res)
    }).catch(error => {
      return Promise.reject(error)
    })
}

async function removeFavorite(vacancyId) {
  return fetchWrapper(`${api_url}/user/favourite/${vacancyId}/`, {
        method: 'DELETE',
    }).then(res => {
      return Promise.resolve()
    }).catch(error => {
      return Promise.reject(error)
    })
}

function setPassword(form) {
  return fetchWrapper(`${api_url}/auth/users/set_password/`, {
        method: 'POST',
        body: JSON.stringify({
          ...form
        })
    }).then(res => {
      return Promise.resolve(res)
    }).catch(error => {
      return Promise.reject(error)
    })
}

function setPasswordConfirm(form) {
  return fetchWrapper(`${api_url}/auth/users/reset_password_confirm/`, {
      method: 'POST',
      body: JSON.stringify({
        ...form
      })
  }).then(res => {
    return Promise.resolve(res)
  }).catch(error => {
    return Promise.reject(error)
  })
}

function passwordReset(email) {
  return fetchWrapper(`${api_url}/auth/users/reset_password/`, {
      method: 'POST',
      body: JSON.stringify({
          email
      })
  }).then(res => {
    return Promise.resolve(res)
  }).catch(error => {
    return Promise.reject(error)
  })
}

function removeAccount(password) {
  return fetchWrapper(`${api_url}/auth/users/me/`, {
        method: 'DELETE',
        body: JSON.stringify({
          current_password: password
        }),
        redirect: 'follow',
    }).then(res => {
      return Promise.resolve(res)
    }).catch(error => {
      return Promise.reject(error)
    })
}

export async function refreshAccessToken() {

  return fetchWrapper(`${api_url}/token/refresh/`, {
    method: 'POST',
    body: JSON.stringify({
        refresh: getRefreshToken()
    })
}).then(res => {
  setToken(res);
  return Promise.resolve(res)
}).catch(error => {
  return Promise.reject(error)
})

}
