import React, {useState} from 'react';

import * as S from './Vacancies.module.scss';

import SiteSettings from '../../SiteSettings';
import Share from '../Share';

const ShareSearch = props => {
  const [toggleShare, settoggleShare] = useState(false);

  const handleToggle = () => {
    settoggleShare(!toggleShare);
  }

  return(
    <div className={S.filter}>
      <span 
        onClick={handleToggle}
        className={S.link}>
        Deel zoekresultaten
      </span>
      {
        toggleShare && 
          <div>
            <Share title={`Mijn zoekresultaten van ${SiteSettings.REACT_APP_TITLE}`} />
          </div>
      }
      
    </div>
  )
}

export default ShareSearch;