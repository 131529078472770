import React, {useState} from 'react';

import classNames from 'classnames';

import MultiList from '@appbaseio/reactivesearch/lib/components/list/MultiList';

import * as S from '../Vacancies/Vacancies.module.scss';

const NCIFFilter = props => {
  const {
    defaultQuery,
    renderCount,
    transformWorkLevelData,
    transformOrganisations,
    transformCountries,
    transformDisciplines
  } = props;

  const [toggleFilters, setToggleFilters] = useState([
    {
      name: "Carrièreniveau",
      toggled: false
    },
    {
      name: "Dienstverband",
      toggled: false
    },
    {
      name: "Organisatie",
      toggled: false
    },
    {
      name: "Land",
      toggled: false
    },
    {
      name: "Vakgebied",
      toggled: false
    }
  ]);

  const Title = props => {
    return (
      <div
        className={classNames(S.toggleBtn, {[S.open]: isToggled(props.title) })}
        onClick={() => toggle(props.title)}>
        {props.title}
      </div>
    );
  }

  const toggle  = filter => {
    setToggleFilters(filters => {
      let index = filters.findIndex(f => f.name === filter);
      let curState = filters.find(f => f.name === filter).toggled;
      let newFilters = [...filters];
      filters[index].toggled = !curState;
      return newFilters;
    })
  }

  const isToggled = filter => {
    let toggled = false;
    toggled = toggleFilters.find(f => f.name === filter).toggled;
    return toggled 
  }

  return (
    <div>

      <MultiList
        className={classNames(S.toggleFilter, {[S.open]: isToggled('Organisatie')})}
        componentId="OrganisationFilter"
        dataField="organisation_name.raw"
        showSearch={true}
        transformData={transformOrganisations}
        size={1000}
        sortBy="asc"
        URLParams
        title={<Title title="Organisatie" />}
        filterLabel="Organisatie"
        react={{
          and: [
            'SearchFilter',
            'CountryFilter',
            'SavedVacanciesFilter',
            'ContinentFilter',
            'DisciplineFilter',
            'workThinkLevelFilter'
            ]
        }}
        renderItem={renderCount}
        defaultQuery={defaultQuery}
      />

      <MultiList
        className={classNames(S.toggleFilter, {[S.open]: isToggled('Carrièreniveau')})}

        componentId="workThinkLevelFilter"
        dataField="work_think_level.raw"
        title={<Title title="Carrièreniveau" />}
        transformData={transformWorkLevelData}
        URLParams
        size={1000}
        filterLabel="Carri&egrave;reniveau"
        showSearch={false}
        renderItem={renderCount}
        react={{
          and: [
            'SearchFilter',
            'CountryFilter',
            'OrganisationFilter',
            'SavedVacanciesFilter',
            'ContinentFilter',
            'DisciplineFilter'
          ]
        }}
        defaultQuery={defaultQuery}
      />

      <MultiList
        className={classNames(S.toggleFilter, {[S.open]: isToggled('Vakgebied')})}

        componentId="DisciplineFilter"
        dataField="discipline.raw"
        title={<Title title="Vakgebied" />}
        transformData={transformDisciplines}
        URLParams
        size={1000}
        filterLabel="Vakgebied"
        sortBy="asc"
        showSearch={false}
        renderItem={renderCount}
        react={{
          and: [
            'SearchFilter',
            'CountryFilter',
            'OrganisationFilter',
            'SavedVacanciesFilter',
            'ContinentFilter',
            'workThinkLevelFilter'
          ]
        }}
        defaultQuery={defaultQuery}
      />

      <MultiList
        className={classNames(S.toggleFilter, {[S.open]: isToggled('Land')})}
        componentId="CountryFilter"
        dataField="country.raw"
        sortBy="asc"
        transformData={transformCountries}
        title={<Title title="Land" />}
        size={1000}
        filterLabel="Land"
        showSearch={true}
        URLParams
        react={{
          and: [
            'SearchFilter',
            'OrganisationFilter',
            'SavedVacanciesFilter',
            'ContinentFilter',
            'DisciplineFilter',
            'workThinkLevelFilter'
          ]
        }}
        renderItem={renderCount}
        defaultQuery={defaultQuery}
      />

    </div>
  )
}

NCIFFilter.filtersNames = [
  'SearchFilter',
  'CountryFilter',
  'OrganisationFilter',
  'DisciplineFilter',
  'workThinkLevelFilter'
]

export default NCIFFilter
