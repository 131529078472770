import SiteSettings from '../SiteSettings';
import {isEmptyObject} from '../helpers';

export const appStorage = {};

const api_url = SiteSettings.REACT_APP_API_URL;

// User utility functions
export function loggedIn(user) {
    if (!!user && !isEmptyObject(user)) {
        return !!user.loggedIn;
    } else {
        return !!getUser();
    }
}

export function logOut() {
    appStorage.clear();
}

export function saveUser(user) {
    appStorage.setItem('profile', JSON.stringify(user));
}

/**
 * Get authenticated user from app storage (see appStorage above).
 */
export function getUser() {
    try {
        return JSON.parse(sessionStorage.getItem('profile'))
    }
    catch(e) {
        return null;
    }
}

/**
 * Get user token.
 */
export function getUserToken() {
    const user = getUser();
    if (user) {
      return user.token;
    }
}

export function setProfile(profile) {
  sessionStorage.setItem('profile', JSON.stringify(profile))
}

export function getProfile() {
  const profile = sessionStorage.getItem('profile');
  return profile ? JSON.parse(sessionStorage.profile) : {}
}

export function setToken(token) {
  if(token.access) {
    sessionStorage.setItem('access', token.access);
  }
  if(token.refresh){
      sessionStorage.setItem('refresh', token.refresh);
  }
  if(token.ephemeral_token){
    sessionStorage.setItem('ephemeral_token', token.ephemeral_token);
  }
}

export function getToken() {
  // Retrieves the user token from sessionStorage
  return sessionStorage.getItem('access');
}

export function getRefreshToken() {
  // Retrieves the user token from sessionStorage
  return sessionStorage.getItem('refresh');
}

export function getEphemeralToken() {
  return sessionStorage.getItem('ephemeral_token');
}
const checkStatus = response => {
    if (response.status >= 200 && response.status < 300) {
      return response;
    }

    return response.json().then(json => {
      return Promise.reject({
        status: response.status,
        ok: false,
        statusText: response.statusText,
        body: json
      });
    });
  };

  const parseJSON = response => {
    if (response.status === 204 || response.status === 205) {
      return null;
    }
    return response.json();
  };

  const handleError = error => {
    error.response = {
      status: 0,
      statusText:
        "Cannot connect. Please make sure you are connected to internet."
    };
    throw error;
  };



  export async function fetchWrapper(url, options) {
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };

    if (getToken() && url.startsWith(api_url)) {
        headers['Authorization'] = 'Bearer ' + getToken()
    }

    return fetch(url, {
            headers,
            ...options
        })
        .catch(handleError) // handle network issues
        .then(checkStatus)
        .then(parseJSON)
        .catch(async error => {
          throw error;
        });
  }
