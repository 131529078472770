import React from 'react';
import {NavLink} from 'react-router-dom';

import * as S from './Account.module.scss';

const AccountWrapper = props => {
  return(
    <div className={S.root}>
      <div className={S.inner}>
        {props.goBackTitle ? (
          <NavLink className={S.goBackLink} to={props.goBackLink}>{props.goBackTitle}</NavLink>
        ) : (
          <NavLink className={S.goBackLink} to="/">Naar vacatures</NavLink>
        )}
        <div className={S.compWrapper}>
          {props.title && <h2 className={S.title}>{props.title}</h2>}
          {props.children}
        </div>
      </div>
    </div>
  )
}

export default AccountWrapper;