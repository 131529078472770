import React from 'react';
import Layout from '../../components/Layout';
import {connect} from 'react-redux';

import PasswordReset from '../../components/PasswordReset'

import {isEmptyObject} from '../../helpers';

const PasswordResetRoot = props => {
    return (
      <Layout 
        parent="Vacatures"
        subParent="Mijn profiel"
        title="Wachtwoord wijzigen"
        >
       {!isEmptyObject(props.authentication) &&  <PasswordReset 
          authentication={props.authentication} 
         />}  
      </Layout>
    )
}


const mapStateToProps = (state, ownProps) => {
  return {
    authentication: state.authentication
  }
}
export default connect(mapStateToProps)(PasswordResetRoot);