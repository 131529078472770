import React from 'react';
import Layout from '../../components/Layout';
import {HelpWbdEU, HelpNCIF} from '../../components/Help';
import {connect} from 'react-redux';

import SiteSettings from '../../SiteSettings';
const HelpRoot = props => {
    return (
      <Layout 
        parent="Vacatures"
        title="Help">
          {SiteSettings.REACT_APP_THEME === 'wbdeu' ? <HelpWbdEU /> : <HelpNCIF />}
      </Layout>
    )
}


const mapStateToProps = state => {
  return {
    authentication: state.authentication
  }
}
export default connect(mapStateToProps)(HelpRoot);