import React from 'react';

import {withRouter} from 'react-router-dom';

import * as F from '../../assets/styles/shared/forms.module.scss';

import AccountWrapper from './AccountWrapper';

import {userActions} from '../../actions';

import {connect} from 'react-redux';


const RemoveAccount = (props) => {
  const [state, setState] = React.useState('');
  const handleSubmit = (e) => {
    e.preventDefault();
    props.dispatch(userActions.removeAccount(state))
  };

  return(
    <AccountWrapper
      title="Profiel verwijderen"
      goBackTitle="Terug naar mijn profiel"
      goBackLink={"/account"}>
      <form onSubmit={handleSubmit}>
        <div>
            <p>U staat op het punt uw account te verwijderen met alle daarbij behorende data.</p>
            <p><b>Weet u zeker dat u uw account wilt verwijderen?</b></p>
        </div>
        <input
          className={F.formControl}
          id="current_password"
          type="password"
          value={state}
          onChange={e => setState(e.target.value)}
          placeholder="Type uw huidige wachtwoord ter bevestiging"/>
        <br />
        <br />
        <input
          className={F.delete}
          type="submit"
          value="Profiel verwijderen"/>
      </form>
    </AccountWrapper>
  )
}

const mapStateToProps = state => {
  return {
    error: state.authentication.error,
    isRegistered: state.authentication.isRegistered,
    user: state.authentication.user
  }
}

export default connect(mapStateToProps)(withRouter(RemoveAccount));
