import React, {useState, Fragment, useEffect} from 'react';

import * as F from '../../assets/styles/shared/forms.module.scss';
import { connect } from 'react-redux';

const SavedSearch = (props) => {

  const [form, setValues] = useState({
    description: '',
    update: 'never'
  });

  const updateField = e => {
    setValues({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if(props.item) {
      props.handleUpdate(props.item.id, form);
    } else {
      props.handleSave(form);
    };
  };

  const handleError = (errors) => {
    if(errors.detail){
        return (<div className={F.formError} key={errors.detail}>{errors.detail}</div>);
    }
    return (
      Object.keys(errors)
        .map(er=> {
          return errors[er].map(status => {
            return (
              <div className={F.formError} key={status}>
              {status}
            </div>
            )
          })
        })
    )
  }

  useEffect(() => {
      if(props.item) {
        setValues({description: props.item.filter_name, update: props.item.periodicity})
      }
  }, [props.item])

  return (
    <Fragment>
     {props.error &&  handleError(props.error.body)}
      <form onSubmit={handleSubmit}>
        <h2 className={F.title}>Deze zoekopdracht {props.item ? 'aanpassen' : 'bewaren'}</h2>
        <div className={F.formGroup}>
            <label htmlFor="description">Korte beschrijving</label>
            <input
              className={F.formControl}
              id="description"
              type="text"
              value={form.description}
              name="description"
              onChange={updateField} />
        </div>
        <div className={F.formGroup}>
            <label htmlFor="update">Wilt u updates over nieuwe vacatures ontvangen?</label>
            <div className={F.radioGroup}>
              <label htmlFor="never">
                <input 
                  id="never" 
                  value="never" 
                  checked={form.update === 'never'}
                  onChange={updateField} 
                  name="update" 
                  type="radio"/>
                  Niet
                  <span className={F.checkmark}></span>
              </label>
              <label htmlFor="daily">
                <input 
                id="daily" 
                value="daily" 
                checked={form.update === 'daily'}
                onChange={updateField} 
                name="update" 
                type="radio"/>
                Elke dag
                <span className={F.checkmark}></span>
              </label>
              <label htmlFor="weekly">
                <input 
                id="weekly" 
                checked={form.update === 'weekly'}
                value="weekly" 
                onChange={updateField} 
                name="update" 
                type="radio"/>
                Elke Week
                <span className={F.checkmark}></span>
              </label>
            </div>
        </div>
        <input
          className={F.submit}
          disabled={!form.description || !form.update}
          type="submit"
          id="id_button_save_search_result"
          name="button_save_search_result"
          value="Opslaan"/>
        <input 
          className={F.seconday}
          type="button" 
          value="Annuleren" 
          onClick={props.hide}/>
        {props.item &&  <input 
          className={F.delete}
          type="button" 
          value="Verwijderen" 
          onClick={() => props.handleDelete(props.item.id)}/>}
      </form>
    </Fragment>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    error: state.authentication.error
  }
}
export default connect(mapStateToProps)(SavedSearch);
