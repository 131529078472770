import { savedVacanciesActionTypes } from '../actiontypes/savedVacancies';

export const savedVacanciesActions = {
    getAll,
    add,
    remove
};

function getAll() {
    return dispatch => {
        dispatch(request());
    };

    function request() { return { type: savedVacanciesActionTypes.GET_SAVED_VACANCIES_REQUEST } }
}

function add(id) {
  return dispatch => {
      dispatch(request());
      dispatch(success(id));
  };

  function request() { return { type: savedVacanciesActionTypes.ADD_SAVED_VACANCY_REQUEST } }
  function success(id) { return { type: savedVacanciesActionTypes.ADD_SAVED_VACANCY_SUCCESS, id } }
}


function remove(id) {
  return dispatch => {
      dispatch(request());
      dispatch(success(id));
  };

  function request() { return { type: savedVacanciesActionTypes.REMOVE_SAVED_VACANCY_REQUEST } }
  function success(id) { return { type: savedVacanciesActionTypes.REMOVE_SAVED_VACANCY_SUCCESS, id } }
}