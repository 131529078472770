import React, {Fragment} from 'react';
import classNames from 'classnames';

import * as S from './SavedSearches.module.scss';

const Item = props => {
  return (
    <div className={classNames(S.item, {[S.selected]: props.item.id === props.selectedItem})}>
      <span 
        className={S.link}
        onClick={() => props.goTo(props.item.id, props.item.filter)}>
        {props.item.filter_name}
      </span>
      {
        props.item.id === props.selectedItem ? (
          <Fragment>
          {
            props.showSaveBtn && 
                <span 
                  className={S.itemSave}
                  onClick={() => props.handleSaveCurrent(props.item)}>
                </span>
          }
          </Fragment>
        ) : null
      }
      <span 
        onClick={() => props.handleEdit(props.item)}
        className={S.itemEdit}></span>
    </div>
  )
}

export default Item;