import { userActionTypes } from '../actiontypes/users';

const initialState = {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userActionTypes.LOGIN_REQUEST:
      return {
        loggedIn: false,
        user: action.user
      };
    case userActionTypes.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user
      };
    case userActionTypes.LOGIN_FAILURE:
      return {
        error: action.error
      };
    case 'LOGOUT':
      return initialState;
    case userActionTypes.REGISTER_FAILURE:
      return {
        ...state,
        error: action.error,
        isRegistered: false
      }

    case userActionTypes.ADD_FAVORITE_SUCCESS:
      return {
        ...state,
        user: action.user
      };

    case userActionTypes.REMOVE_FAVORITE_SUCCESS:
      return {
        ...state,
        user: action.user
      };

    case userActionTypes.REGISTER_REQUEST:
      return {
        ...state,
        error: ''
      }
    case userActionTypes.REGISTER_SUCCESS:
      return {
        ...state,
        error: '',
        isRegistered:true
      };
      
    case userActionTypes.PASSWORD_FORGOT_SUCCESS:
      return {
        ...state,
        isRequested: true
      }
    case userActionTypes.PASSWORD_FORGOT_FAILURE:
      return {
        ...state,
        isRequested: false,
        error: action.error
      }
    case userActionTypes.PASSWORD_RESET_REQUEST:
      return {
        ...state,
        error: ''
      };
    case userActionTypes.PASSWORD_RESET_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case userActionTypes.ENABLE_MFA_FAILURE:
      return {
          ...state,
          error: action.error
      };
    case userActionTypes.LOGIN_REQUIRE_MFA:
      return {
          ...state,
          error: action.error
      };
    case userActionTypes.SET_PASSWORD_CONFIRM_SUCCESS:
      return {
        ...state,
        error: ''
      }
    case userActionTypes.SET_PASSWORD_CONFIRM_FAILURE:
      return {
        ...state,
        error: action.error
      }
    case userActionTypes.ACTIVATE_FAILURE:
      return {
        ...state,
        error: action.error
      }

    case userActionTypes.DISABLE_MFA_SUCCESS:
      return {
        ...state,
        user: action.user
      };

    case userActionTypes.ENABLE_MFA_SUCCESS:
      return {
        ...state,
        user: action.user
      };
    case userActionTypes.DELETE_FAILURE:
      return {
        ...state,
        error: action.error
      };
    default:
      return state
  }
}
