import SiteSettings from '../SiteSettings';
import {fetchWrapper} from "../actions/lib";

export const vacancyService = {
  getVacancy,
}


let domain = SiteSettings.REACT_APP_ES_URL

function getVacancy(vacancyId) {
  return fetchWrapper(`${domain}/vacancy/_search?q=id:${vacancyId}`, {
        method: 'GET',
    }).then(res => {
      return Promise.resolve(res)
    }).catch(error => {
      return Promise.reject(error)
    })
}
