import { vacancyActionTypes } from "../actiontypes/vacancies";

export function organisations(state = {}, action) {

    if (typeof state === 'function') {
        state = state() || {};
    }

    switch (action.type) {
        case vacancyActionTypes.GET_ORGANISATIONS_RESPONSE:
            if (action.error === true) return state;
            else return {...state, ...action.organisations};
        default:
            return state;
    }
}
