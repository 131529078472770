import React from 'react';
import Layout from '../../components/Layout';
import MFA from '../../components/Account/MFA';

const MFARoot = props => {
  return (
    <Layout 
      parent="Vacatures"
      subParent="Mijn profiel"
      title="Twee-factor-authenticatie Instellen">
        <MFA />
    </Layout>
  )
}

export default MFARoot;
