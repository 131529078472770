import React from "react";
import * as S from './LoginInfo.module.scss';
import icon from '../../assets/images/info.svg'

const LoginInfo = ({text}) => {
    return <span
        data-text={text}
        className={S.tooltip}
    >
        <img
            width={16}
            height={16}
            alt={text}
            data-text={text}
            src={icon}
        />
    </span>
}

export default LoginInfo
