import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import * as S from './Saved.module.scss';
import {userActions, savedVacanciesActions} from '../../actions';
import classNames from 'classnames';

import Loader from './Loader';

const Saved = (props) => {
  
  const [savedVacancies, setsavedVacancies] = useState([]);
  const [isSaving, setisSaving] = useState(false);

  const handleSaved = (id) => {
    if(props.user.user && id) {
      let userSavedFavs = props.user.user.favourites;
      let isSavedByUser = userSavedFavs.find(fav => fav.id === id);
      setisSaving(true);
      if(isSavedByUser) {
        props.dispatch(userActions.removeFavorite(id))
        .then(res => {
          if(props.refreshRefClick) {
            props.refreshRefClick();
          }
        });
      } else {
        props.dispatch(userActions.addFavorite(id));
      }
    } else if (id) {
      if(props.savedVacancies.vacancies.includes(id)) {
        props.dispatch(savedVacanciesActions.remove(id));
        if(props.refreshRefClick) {
          props.refreshRefClick();
        }
      } else {
        props.dispatch(savedVacanciesActions.add(id));
      }
    }
  }

  const isSaved = (id) => {
    if(props.user.user && id) {
      let isSaved = savedVacancies.find(v => v.id === id);
      if(isSaved) {
        return true;
      } 
    } else if (id) {
      if(props.savedVacancies.vacancies) {
        if(props.savedVacancies.vacancies.includes(id)) {
          return true;
        }  
      }
    }
  }

  useEffect(() => {
    if(props.user.user) {
      setsavedVacancies(props.user.user.favourites);
      setisSaving(false);
    } 
  }, [props.user.user]);
  
  return(
      <div className={classNames(S.root, {[S.detailPage]: props.detailPage})}>
        {isSaving ? (
          <Loader inline />
        ) : (
          isSaved(props.id) ? (
            <span 
              onClick={() => handleSaved(props.id)}
              className={classNames(S.link, S.saved)}>
              Opgeslagen
            </span>
          ) : (
            <span 
              className={S.link}
              onClick={() => handleSaved(props.id)}>
              Opslaan
            </span>
          )
        )}
      </div>
  )
}

const mapStateToProps = state => {
  return {
    savedVacancies: state.savedVacancies
  }
}


export default connect(mapStateToProps)(Saved);