import SiteSettings from '../SiteSettings';
import {vacancyActionTypes} from "../actiontypes/vacancies";

import {fetchWrapper} from '../actions/lib';

export const vacancyActions = {
    getVacancy,
    getOrganisations,
    getNonCountries,
    getDisciplines
};

const api_url = SiteSettings.REACT_APP_API_URL;
const es_url = SiteSettings.REACT_APP_ES_URL

function getVacancy(vacancy_id) {
    return dispatch => {
        dispatch(request({ vacancy_id }));


        fetchWrapper(`${es_url}/vacancy/_search?q=id:${vacancy_id}`)
            .then(
                res => {
                    dispatch(success(res));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(id) { return { type: vacancyActionTypes.GET_VACANCY_REQUEST, id } }
    function success(vacancy) { return { type: vacancyActionTypes.GET_VACANCY_RESPONSE, vacancy } }
    function failure(error) { return { type: vacancyActionTypes.GET_VACANCY_FAILURE, error } }
}

function getOrganisations() {
    return dispatch => {
        dispatch(request());

        fetchWrapper(`${api_url}/mappings`)
            .then(
                res => {
                    dispatch(success(res));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: vacancyActionTypes.GET_ORGANISATIONS_REQUEST } }
    function success(organisations) { return { type: vacancyActionTypes.GET_ORGANISATIONS_RESPONSE, organisations } }
    function failure(error) { return { type: vacancyActionTypes.GET_ORGANISATIONS_FAILURE, error } }
}

function getNonCountries() {
    return dispatch => {
        dispatch(request());

        fetchWrapper(`${api_url}/noncountries/`)
            .then(
                res => {
                    dispatch(success(res));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: vacancyActionTypes.GET_NONCOUNTRIES_REQUEST } }
    function success(noncountries) { return { type: vacancyActionTypes.GET_NONCOUNTRIES_RESPONSE, noncountries } }
    function failure(error) { return { type: vacancyActionTypes.GET_NONCOUNTRIES_FAILURE, error } }
}

function getDisciplines() {
    return dispatch => {
        dispatch(request());

        fetchWrapper(`${api_url}/disciplines/`)
            .then(
                res => {
                    dispatch(success(res));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: vacancyActionTypes.GET_DISCIPLINES_REQUEST } }
    function success(disciplines) { return { type: vacancyActionTypes.GET_DISCIPLINES_RESPONSE, disciplines } }
    function failure(error) { return { type: vacancyActionTypes.GET_DISCIPLINES_FAILURE, error } }
}
