import React from 'react';

import MultiList from '@appbaseio/reactivesearch/lib/components/list/MultiList';

import * as S from '../Vacancies/Vacancies.module.scss';

const WbdEUFilter = props => {
  const {
    defaultQuery,
    renderCount,
    transformOrganisations,
    transformCountries,
    transformDisciplines
  } = props;

  return (
    <div>

      <MultiList
        className={S.filter}
        componentId="DisciplineFilter"
        dataField="discipline.raw"
        title="Vakgebied"
        transformData={transformDisciplines}
        URLParams
        size={1000}
        filterLabel="Vakgebied"
        sortBy="asc"
        showSearch={false}
        renderItem={renderCount}
        react={{
          and: [
            'SearchFilter',
            'CountryFilter',
            'CityFilter',
            'OrganisationFilter',
            'SavedVacanciesFilter',
            'ContractTypeFilter'
            ]
        }}
        defaultQuery={defaultQuery}
      />

       <MultiList
        className={S.filter}
        componentId="ContractTypeFilter"
        dataField="contract_type.raw"
        URLParams
        size={1000}
        title="Dienstverband"
        filterLabel="Dienstverband"
        sortBy="asc"
        showSearch={false}
        renderItem={renderCount}
        react={{
          and: [
            'SearchFilter',
            'CountryFilter',
            'CityFilter',
            'OrganisationFilter',
            'SavedVacanciesFilter',
            'DisciplineFilter'
          ]
        }}
        defaultQuery={defaultQuery}
      />

      <MultiList
        className={S.filter}
        componentId="OrganisationFilter"
        dataField="organisation_name.raw"
        showSearch={true}
        size={1000}
        transformData={transformOrganisations}
        sortBy="asc"
        URLParams
        title="Organisatie"
        filterLabel="Organisatie"
        react={{
          and: [
            'SearchFilter',
            'CountryFilter',
            'CityFilter',
            'SavedVacanciesFilter',
            'ContractTypeFilter',
            'DisciplineFilter'
            ]
        }}
        renderItem={renderCount}
        defaultQuery={defaultQuery}
      />

      <MultiList
        className={S.filter}
        componentId="CountryFilter"
        dataField="country.raw"
        sortBy="asc"
        transformData={transformCountries}
        title="Land"
        size={1000}
        filterLabel="Land"
        showSearch={true}
        URLParams
        react={{
          and: [
            'SearchFilter',
            'CityFilter',
            'OrganisationFilter',
            'SavedVacanciesFilter',
            'ContractTypeFilter',
            'DisciplineFilter'
            ]
        }}
        renderItem={renderCount}
        defaultQuery={defaultQuery}
      />

      <MultiList
        className={S.filter}
        componentId="CityFilter"
        dataField="city.raw"
        showSearch={false}
        sortBy="asc"
        size={1000}
        URLParams
        title="Standplaats"
        filterLabel="Standplaats"
        react={{
        and: [
          'SearchFilter',
          'CountryFilter',
          'OrganisationFilter',
          'SavedVacanciesFilter',
          'ContractTypeFilter',
          'DisciplineFilter'
          ]
      }}
        renderItem={renderCount}
        defaultQuery={defaultQuery}
      />

    </div>
  )
}

WbdEUFilter.filtersNames = [
  'SearchFilter',
  'CountryFilter',
  'CityFilter',
  'OrganisationFilter',
  'ContractTypeFilter',
  'DisciplineFilter'
]

export default WbdEUFilter
