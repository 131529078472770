
import React, {Fragment} from 'react';
import classNames from 'classnames';
import * as S from './Footer.module.scss';
import {NavLink} from 'react-router-dom';

import SiteSettings from '../../SiteSettings';
import wbdeuLogo from '../../assets/images/werkenbijdeeu.png';

const Footer = props => {
  let isWbdEU = SiteSettings.REACT_APP_THEME === 'wbdeu'
  return(
    <Fragment>
      {
        isWbdEU &&
        <div className={S.euLogo}>
          <img
            alt={SiteSettings.REACT_APP_TITLE}
            src={wbdeuLogo} />
        </div>
      }
      <footer className={classNames(S.root, [S[SiteSettings.REACT_APP_THEME]])}>
        <div className={S.inner}>
          <p>
          </p>
          <div className={S.column}>
            <h3>Over deze site</h3>
            <ul>
              <li><NavLink to="/help">Help</NavLink></li>
              <li><a href={isWbdEU ? "https://www.werkenbijdeeu.nl/privacy" : "https://www.werkenvoorinternationaleorganisaties.nl/privacy" }>Privacy</a></li>
              <li><a href={isWbdEU ? "https://www.werkenbijdeeu.nl/cookies" : "https://www.werkenvoorinternationaleorganisaties.nl/cookies"}>Cookies</a></li>
              <li><a href={isWbdEU ? "https://www.werkenbijdeeu.nl/toegankelijkheid" : "https://www.werkenvoorinternationaleorganisaties.nl/toegankelijkheid"}>Toegankelijkheid</a></li>
            </ul>
          </div>
        </div>
      </footer>
    </Fragment>
  )
}


export default Footer;
