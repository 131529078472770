import React, {useState, Fragment, useEffect} from 'react';

import {withRouter} from 'react-router-dom';

import * as S from './Account.module.scss';
import * as F from '../../assets/styles/shared/forms.module.scss';
import classNames from 'classnames';

import AccountWrapper from './AccountWrapper';

import {userActions} from '../../actions';
import { userActionTypes } from '../../actiontypes/users';

import {connect} from 'react-redux';

const SetPassword = (props) => {

  const [error, seterror] = useState()

  const [form, setValues] = useState({
    uid: '',
    token: '',
    new_password: '',
    re_new_password: ''
  });

  const updateField = e => {
    setValues({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const password_validate = p  => {
    let re = {
      'capital' : /[A-Z]/,
      'digit'   : /\d/,
      'full'    : /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])([^\s]){8,16}$/
    };
    return re.full.test(p);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    seterror('')
    props.dispatch({type: userActionTypes.SET_PASSWORD_CONFIRM_REQUEST})
    if(form.new_password && form.re_new_password) {
      if(password_validate(form.new_password)) {
        if(form.new_password === form.re_new_password) {
          props.dispatch(userActions.passwordResetConfirm(form));
        } else {
          seterror('Wachtwoorden zijn niet gelijk');
        }
      } else {
        seterror('Wachtwoord voldoet niet aan de eisen');
      }
    }
  };

  const handleError = (errors) => {
    return (
      Object.keys(errors)
        .map(er=> {
          return errors[er].map(status => {
            return (
              <div
              className={F.formError}
              key={status}>
              {status}
            </div>
            )
          })
        })
    )
  }

  useEffect(() => {
    return () => {
      if(props.match.params) {
        let params = props.match.params;
        setValues({
          ...form,
          'uid': params.uid,
          'token': params.token
        })
      }
    };
  }, [props.match, form])

  return(
    <AccountWrapper title="Wachtwoord instellen">
      <form onSubmit={handleSubmit}>
      {error && <div className={F.formError}>{error}</div>}
      {props.error &&  handleError(props.error.body)}
      {props.isRegistered ? (
        <div>U bent aangemeld, bekijk uw mail en activeer uw account</div>
      ) : (
        <Fragment>
          <div className={F.formGroup}>
            <label htmlFor="new_password">Wachtwoord</label>
            <input
              className={F.formControl}
              value={form.password}
              name="new_password"
              type="password"
              onChange={updateField}
              id="new_password"
            />
            <small className={S.infoText}>Minimaal 8 karakters, minimaal 1 letter, minimaal 1 speciaal karakter</small>
          </div>
          <div className={classNames(F.formGroup, F.lastItem)}>
            <label htmlFor="re_new_password">Bevestig wachtwoord</label>
            <input
              className={F.formControl}
              value={form.password_again}
              name="re_new_password"
              type="password"
              onChange={updateField}
              id="re_new_password"
            />

          </div>
          <input
            className={F.submit}
            disabled={!form.re_new_password || !form.re_new_password}
            type="submit"
            value="Wachtwoord wijzigen"/>
            </Fragment>
      )}
      </form>

    </AccountWrapper>
  )
}



const mapStateToProps = state => {
  return {
    error: state.authentication.error,
    isRegistered: state.authentication.isRegistered
  }
}

export default connect(mapStateToProps)(withRouter(SetPassword));
